import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Users from "views/admin/Users.js";
import Roles from "views/admin/Roles.js";
import Product from "views/admin/Product.js";
import Autors from "views/admin/Autors.js";
import Genders from "views/admin/Genders.js";
import Specialty from "views/admin/Specialty.js";
import News from "views/admin/News.js";
import Contacts from "views/admin/Contacts.js";
import Boletins from "views/admin/Boletins.js";
import PublicYourBook from "views/admin/PublicYourBook.js";
import RecomendedBooks from "views/admin/RecomendedBooks.js";
import Videos from "views/admin/Video.js";
import Logs from "views/admin/Logs.js";
import Logout from "../components/logout/logout";
import PrivateRoute from "./PrivateRouter";
import Packs from "views/admin/Packs";
import ContatactWhatsApp from "views/admin/ContactWhatsApp";

class Admin extends Component {
  state = {
    user: null
  }
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user)
      this.setState({ user })
  }
  render() {

    return (
      <>
        <Sidebar user={this.state.user} />
        <div className="relative md:ml-64 bg-blueGray-100">
          <AdminNavbar />
          {/* Header */}
          <HeaderStats />
          <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <Switch>
              {this.state.user !== null && this.state.user.roles === 'ROLE_ADMIN' ?
                <>
                  <PrivateRoute path="/admin/dashboard" exact component={Dashboard} />
                  <PrivateRoute path="/admin/settings" exact component={Settings} />
                  <PrivateRoute path="/admin/tables" exact component={Tables} />
                  <PrivateRoute path="/admin/users" exact component={Users} />
                  <PrivateRoute path="/admin/logs" exact component={Logs} />
                  <PrivateRoute path="/admin/roles" exact component={Roles} />
                  <PrivateRoute path="/admin/product" exact component={Product} />
                  <PrivateRoute path="/admin/autors" exact component={Autors} />
                  <PrivateRoute path="/admin/genders" exact component={Genders} />
                  <PrivateRoute path="/admin/specialty" exact component={Specialty} />
                  <PrivateRoute path="/admin/news" exact component={News} />
                  <PrivateRoute path="/admin/contacts" exact component={Contacts} />
                  <PrivateRoute path="/admin/boletins" exact component={Boletins} />
                  <PrivateRoute path="/admin/recomended_books" exact component={RecomendedBooks} />
                  <PrivateRoute path="/admin/video" exact component={Videos} />
                  <PrivateRoute path="/admin/public-your-book" exact component={PublicYourBook} />
                  <PrivateRoute path="/admin/packs" exact component={Packs} />
                  <PrivateRoute path="/admin/contact_whats_app" exact component={ContatactWhatsApp} />
                  <Route path="/admin/logout" exact component={Logout} />
                  <Redirect from="/admin" to="/admin/dashboard" /></>
                : null}
            </Switch>
          </div>
        </div>
      </>
    );
  }
}
export default Admin;
