import React from "react";

// components

import CardBoletins from "components/Cards/CardBoletin";
import CardTableBoletins from "components/Cards/CardTableBoletin";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardBoletins />
        </div>
        <div className="w-full px-4">
          <CardTableBoletins />
        </div>
      </div>
    </>
  );
}
