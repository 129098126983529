import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
// components
class CardBoletin extends Component {

  imagenUrl = "";
  pdfUrl = "";

  state = {
    id: -1,
    title: '',
    FileImage: '',
    FilePdf: '',
    error: {},
    loading: false,
  }
  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  //handle error of inputs
  handleError = () => {
    let error = {};
    const { title, FileImage, FilePdf } = this.state;
    let check = false;
    if (!title) {
      check = true;
      error['title'] = "Debe introducir un título";
    }

    if ((FileImage == null || FileImage == '') && this.state.id == -1) {
      error['image'] = "Debe especificar una imagen para el boletín";
      check = true;
    } else if (FileImage.name !== undefined && !FileImage.name.toLowerCase().match(/\.(jpg|jpeg|gif|png)$/)) {
      error['image'] = "Solo se admiten archivos de tipo imagen";
      check = true;
    }

    if ((FilePdf == null || FilePdf == '') && this.state.id == -1) {
      error['pdf'] = "Debe especificar un fragmento para el boletín en pdf";
      check = true;
    } else if (FilePdf.name !== undefined && !FilePdf.name.match(/\.(pdf|PDF)$/)) {
      error['pdf'] = "Solo se admiten archivos de tipo PDF";
      check = true;
    }

    this.setState({ error })
    return check;
  }

  addBoletin = async (e) => {
    e.preventDefault();
    let error = this.handleError();
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user != null) {
      if (!error) {

        this.setState({ loading: true });

        const { title, FileImage, FilePdf } = this.state;

        let boletin = {
          title: title,
        }

        var titleLowerCase = title.trim().toLowerCase();
        var size = titleLowerCase.length;
        var titlePdf = '';
        var c = '';
        for (var i = 0; i < size; i++) {
          c = titleLowerCase.charAt(i);
          if (c != ' ') {
            titlePdf += c;
          }
        }

        if (this.state.id !== -1) {//para comprobar si va a actualizar
          if (FileImage !== null && FileImage !== '') {
            //obtengo el libro         
            axios.get(`${direction.API_BOLETIN}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.picture_address != null) {
                let arr = res.data.picture_address.split("/");
                let oldImagen = arr[arr.length - 1];
                //elimino la imagen anterior
                axios.delete(`${direction.API_UPLOAD}image/${oldImagen}`, {
                  params: {
                    name: oldImagen
                  }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
                this.imagenUrl = "";
              }
            });
          }//aki acaba

          if (FilePdf !== null && FilePdf !== '') {
            //obtengo el boletin         
            axios.get(`${direction.API_BOLETIN}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.pdf_address != null) {
                var arr = res.data.pdf_address.split("/");
                let oldPdf = arr[arr.length - 1];

                //elimino el pdf anterior
                axios.delete(`${direction.API_UPLOAD}document/${oldPdf}`, {
                  params: {
                    name: oldPdf
                  }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
                this.pdfUrl = "";
              }
            });
          }
        }

        if (FileImage !== null && FileImage !== '') {
          const data = new FormData();
          data.append('imageFile', FileImage);
          var prefix = 'boletin-' + titlePdf;
          let { data: result } = await axios.post(direction.API_UPLOAD, data, {
            headers: {
              'x-auth-token': user.accessToken
            },
            params: {
              'prefix': prefix
            }
          });
          if (result) {
            boletin['picture_address'] = `${direction.API_UPLOAD}image/${prefix}-${result.originalname}`;
          }
        }

        if (FilePdf !== null && FilePdf != '') {
          const data = new FormData();
          data.append('docsFile', FilePdf);
          var prefix = 'boletin-' + titlePdf;
          let { data: result } = await axios.post(`${direction.API_UPLOAD}file`, data, {
            headers: {
              'x-auth-token': user.accessToken
            },
            params: {
              'prefix': prefix
            }
          })
          if (result) {
            boletin['pdf_address'] = `${direction.API_UPLOAD}documents/${prefix}-${result.originalname}`;
          }
        }

        if (this.state.id !== -1) {
          boletin['id'] = this.state.id;
          axios.put(direction.API_BOLETIN, boletin, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se ha actualizado correctamente el boletín",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.imagenUrl = "";
              this.pdfUrl = "";
              this.cleanData();
            }
          }).catch((e) => {
            if (e)

            this.setState({ loading: false });

              swal({
                title: "Upps, algo salió mal, inténtelo más tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
          });
        } else {
          axios.post(direction.API_BOLETIN, { "boletin": boletin }, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Boletín insertado correctamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.imagenUrl = "";
              this.pdfUrl = "";
              this.cleanData();
            }
          });
        }
      } else {

        this.setState({ loading: false });
        
        swal({
          title: "Upss, Algo salió mal, revise los datos a insertar",
          text: "Haga click en el botón!",
          icon: "error",
        });
      }
    }
  }

  cleanData = () => {
    this.setState({
      id: -1,
      title: '',
      FileImage: '',
      FilePdf: ''
    });

    document.getElementById('idImgBoletin').value = null;
    document.getElementById('idPdfBoletin').value = null;

    this.props.onRenderBoletinTable();
  }
  
  getBoletin = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      if (id !== undefined) {
        axios.get(`${direction.API_BOLETIN}${id}`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(res => {
          if (res.status === 200) {
            this.imagenUrl = res.data.picture_address;
            this.pdfUrl = res.data.pdf_address;

            this.setState({
              id: res.data.id,
              title: res.data.title,
            });
          }
        }).catch(e => {
          if (e) {
            swal({
              title: `Upss, Algo salió mal inténtelo mas tarde ${e}`,
              text: "Haga click en el botón!",
              icon: "error",
            });
          }
        })
      }
    }
  }

  handleFile = (e) => {
    var file = e.target.files[0];
    this.setState({ FileImage: file })
  }

  handleFile1 = (e) => {
    var file = e.target.files[0];
    this.setState({ FilePdf: file })
  }

  componentDidUpdate() {
    if (this.props.boletinId !== -1) {
      this.getBoletin(this.props.boletinId);
      this.props.onRenderBoletin(-1);
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Información del boletín</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addBoletin}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Boletín</> : <>Actualizar boletín</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Título
                    </label>
                    <input
                      type="text"
                      name="title"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.title}
                    />
                  </div>
                  {this.state.error.title !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.title}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Imagen
                    </label>

                    {(this.imagenUrl !== "" && this.imagenUrl !== null) ?
                      <img
                        src={this.imagenUrl}
                        alt="..."
                        className="w-10 h-10 border-2 border-blueGray-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idImgBoletin"
                      type="file"
                      onChange={this.handleFile}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.image !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.image}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Fragmento del boletín
                    </label>

                    {(this.pdfUrl !== "" && this.pdfUrl !== null) ?
                      <img
                        src={require("assets/img/fragmento.png").default}
                        alt="Fragmento de boletín de la Editorial DMCPherson"
                        className="w-10 h-10 border-2 border-blue-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idPdfBoletin"
                      type="file"
                      onChange={this.handleFile1}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.pdf !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.pdf}
                      </span>
                    </div> : null
                  }
                </div>

              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    boletinId: state.boletinId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderBoletin: (boletinId) => dispatch({ type: actionTypes.RENDER_BOLETIN, boletinId: boletinId }),
    onRenderBoletinTable: () => dispatch({ type: actionTypes.RENDER_BOLETIN_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardBoletin);
