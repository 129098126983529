import React, { Component } from 'react'

import axios from 'axios';
import * as direction from '../../direction/direction';
class Logout extends Component {
    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user')) || null;
        if (user !== null) {
            axios.get(`${direction.API_LOGOUT}`, {
                headers: {
                    'x-auth-token': user.accessToken
                }
            }).then(result => {
                if (result) {
                    localStorage.removeItem('user');
                    window.location = '/auth';
                }
            })
        }
    }
    render() {
        return <div></div>
    }
}

export default Logout;