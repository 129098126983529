import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
// components
class CardContactWhatsApp extends Component {

  state = {
    id: -1,
    number: '',
    error: {},
    loading: false,
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  //handle error of inputs
  handleError = () => {
    let error = {};
    const { number } = this.state;
    let check = false;
    if (!number) {
      check = true;
      error['number'] = "Debe introducir un número de contacto de WhatsApp";
    }

    this.setState({ error })
    return check;
  }

  componentDidMount() {
    this.getContactWatsApp();
  }

  getContactWatsApp() {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_CONTACT_WHATS_APP, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if(res.data){
            this.setState({ number: res.data.number, id: res.data.id });
        }
      });
    }
  }

  addContact = (e) => {
    e.preventDefault()
    let error = this.handleError();
    if (!error) {
      this.setState({ loading: true });
      let { number } = this.state;
     
      const user = JSON.parse(localStorage.getItem('user')) || null;
      if (user !== null) {
        if (this.state.id !== -1) {
          axios.put(direction.API_CONTACT_WHATS_APP, {number}, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se actualizó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss, Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        } else {
          axios.post(direction.API_CONTACT_WHATS_APP, {number}, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se insertó correctamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });
              
              swal({
                title: "Upss, Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        }
      }
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Información del contacto de WhatsApp</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addContact}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Contacto</> : <>Actualizar Contacto</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Número
                    </label>
                    <input
                      type="number"
                      name="number"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.number}
                    />
                  </div>
                  {this.state.error.number !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.number}
                      </span>
                    </div> : null
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    boletinId: state.boletinId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderBoletin: (boletinId) => dispatch({ type: actionTypes.RENDER_BOLETIN, boletinId: boletinId }),
    onRenderBoletinTable: () => dispatch({ type: actionTypes.RENDER_BOLETIN_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardContactWhatsApp);
