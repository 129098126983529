import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
// components
class CardPublicYourBook extends Component {

  componentDidMount() {
    this.getPublicYourBook();
  }

  getPublicYourBook() {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_PUBLIC_YOUR_BOOK, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res) {
          this.setState({ active: res.data.active });
        }
      });
    }
  }

  state = {
    active: false
  }

  handleEvent = (e) => {
    this.setState({ active: e.target.checked });
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.put(`${direction.API_PUBLIC_YOUR_BOOK}${e.target.checked}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res) {
          var title = e.target.checked ? "Se ha activado la opción Publica tu libro" : "Se ha desactivado la opción Publica tu libro";
          swal({
            title: title,
            text: "Haga click en el botón!",
            icon: "info",
          });
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex">
              <h6 className="text-blueGray-700 text-xl font-bold">Publica tu libro</h6>
              <input
                type="checkbox"
                name="published"
                checked={this.state.active}
                onChange={this.handleEvent}
                className="ml-2 border-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-xl focus:outline-none focus:ring ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    publicationId: state.publicationId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderPublication: (publicationId) => dispatch({ type: actionTypes.RENDER_PUBLICATION, publicationId: publicationId }),
    onRenderPublicationTable: () => dispatch({ type: actionTypes.RENDER_PUBLICATION_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardPublicYourBook);
