import React from "react";

// components

import CardVideo from "components/Cards/CardVideo";
import CardTableVideo from "components/Cards/CardTableVideo";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardVideo />
        </div>
        <div className="w-full px-4">
          <CardTableVideo />
        </div>
       
      </div>
    </>
  );
}