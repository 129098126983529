import React from "react";

// components

import CardRoles from "components/Cards/CardRoles";
import CardTableRoles from "components/Cards/CardTableRoles";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardRoles />
        </div>
        <div className="w-full px-4">
          <CardTableRoles />
        </div>
       
      </div>
    </>
  );
}
