import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';

// components

import CardStats from "components/Cards/CardStats.js";

class HeaderStats extends Component {
  state = {
    contBoletin: 0,
    percentBoletin: 0,
    contCatalogue: 0,
    percentCatalogue: 0,
    publications: 0,
    percentPublications: 0,
    total: 0,
    percent: 0,
  }
  componentDidMount() {
    this.getSubscriptors();
  }

  getSubscriptors = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {

      // subscriptors of catalogue
      axios.get(direction.API_SUSCRIPTORS_CATALOGUE + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let cont = 0;
          let contTotal = 0;
          let dateAux = new Date();
          let month = dateAux.getMonth();
          let year = dateAux.getFullYear();
          for (let value of res.data) {
            let date = new Date(value.createdAt);
            if (date.getFullYear() === year)
              if (date.getMonth() === month) {
                cont++;
                contTotal++;
              }
          }
          this.setState({ contCatalogue: cont });
          contTotal += this.state.total;
          this.setState({ total: contTotal });
        }
      });

      // subscriptors of home
      axios.get(direction.API_SUSCRIPTORS_HOME + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let contTotal = 0;
          let dateAux = new Date();
          let month = dateAux.getMonth();
          let year = dateAux.getFullYear();
          for (let value of res.data) {
            let date = new Date(value.createdAt);
            if (date.getFullYear() === year)
              if (date.getMonth() === month) {
                contTotal++;
              }
          }
          contTotal += this.state.total;
          this.setState({ total: contTotal });
        }
      });

      // subscriptors of boletins
      axios.get(direction.API_SUSCRIPTORS_BOLETIN + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let cont = 0;
          let contTotal = 0;
          let dateAux = new Date();
          let month = dateAux.getMonth();
          let year = dateAux.getFullYear();
          for (let value of res.data) {
            let date = new Date(value.createdAt);
            if (date.getFullYear() === year)
              if (date.getMonth() === month) {
                cont++;
                contTotal++;
              }
          }
          this.setState({ contBoletin: cont });
          contTotal += this.state.total;
          this.setState({ total: contTotal });
        }
      });


      //publications
      axios.get(direction.API_PRODUCT + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let dateAux = new Date();
          let month = dateAux.getMonth();
          let year = dateAux.getFullYear();
          let cont = 0;
          let contTotal = 0;

          for (let value of res.data) {
            let date = new Date(value.createdAt);
            if (date.getFullYear() === year)
              if (date.getMonth() === month) {
                cont++;
              }
            contTotal++;
          }

          var p = 0;

          if (cont > 0) {
            p = (cont / contTotal) * 100;
            p = Math.trunc(p);
            this.setState({ percentPublications: p });
          } else {
            this.setState({ percentPublications: 0 });
          }
          this.setState({ publications: cont });

          if (this.state.contCatalogue > 0) {
            p = (this.state.contCatalogue / this.state.total) * 100;
            p = Math.trunc(p);
            this.setState({ percentCatalogue: p });
          } else {
            this.setState({ percentCatalogue: 0 });
          }

          if (this.state.contBoletin > 0) {
            p = this.state.contBoletin / this.state.total * 100;
            p = Math.trunc(p);
            this.setState({ percentBoletin: p });
          } else {
            this.setState({ percentBoletin: 0 });
          }

          if (this.state.total > 0) {
            var temp = this.state.total;
            temp -= this.state.contCatalogue;
            temp -= this.state.contBoletin;
            if (temp > 0) {
              p = temp / this.state.total * 100;
              p = Math.trunc(p);
              this.setState({ percent: p });
            } else {
              this.setState({ percent: 0 });
            }
          } else {
            this.setState({ percent: 0 });
          }
        }
      })
    }
  }
  render() {
    return (
      <>
        {/* Header */}
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="SUSCRIPT. DEL BOLETIN"
                    statTitle={this.state.contBoletin}
                    statPercent={this.state.percentBoletin}
                    statPercentColor="text-emerald-500"
                    statDescripiron="Mes actual"
                    statIconName="fas fa-users"
                    statIconColor="bg-red-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="SUSCRIPT. DE CATALOGO"
                    statTitle={this.state.contCatalogue}
                    statPercent={this.state.percentCatalogue}
                    statPercentColor="text-emerald-500"
                    statDescripiron="Mes actual"
                    statIconName="fas fa-users"
                    statIconColor="bg-orange-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="SUSCRIPT. GENERAL"
                    statTitle={this.state.total}
                    statPercent={this.state.percent}
                    statPercentColor="text-emerald-500"
                    statDescripiron="Mes actual"
                    statIconName="fas fa-users"
                    statIconColor="bg-pink-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="TOTAL DE LIBROS DEL SITIO"
                    statTitle={this.state.publications}
                    statPercent={this.state.percentPublications}
                    statPercentColor="text-emerald-500"
                    statDescripiron="Mes actual"
                    statIconName="fas fa-users"
                    statIconColor="bg-lightBlue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default HeaderStats;
