import React, { Component } from "react";
import Chart from "chart.js";
import axios from 'axios';
import * as direction from '../../direction/direction';
import { render } from "react-dom";

export default class CardLineChart extends Component {
  state = {
    actualYear: [1, 2, 3, 4, 5, 6, 7],
    pastYear: [1, 1, 3, 0, 5, 6, 5],
  }
  componentDidMount() {
    this.getActulYear();
  }
  getActulYear = async () => {
    let labels = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Novienbre",
      "Diciembre"
    ];
    let newLabel = [];
    let actualYear = [];
    let pastYear = [];

    let date = new Date();
    for (let i = 0; i <= date.getMonth(); i++) {
      newLabel.push(labels[i]);
      actualYear.push(0);
      pastYear.push(0);
    }
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      let { data: result } = await axios.get(direction.API_SUSCRIPTORS_CATALOGUE + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      })
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let date1 = new Date(result[i].createdAt);
          if (date.getFullYear() === date1.getFullYear()) {
            actualYear[date1.getMonth()]++;
          }
          else if (date.getFullYear() - 1 === date1.getFullYear()) {
            pastYear[date1.getMonth()]++;
          }
        }
      }
      let { data: res } = await axios.get(direction.API_SUSCRIPTORS_NEWSLETTER + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      })
      if (res) {
        for (let i = 0; i < res.length; i++) {
          let date1 = new Date(result[i].createdAt);
          if (date.getFullYear() === date1.getFullYear()) {
            actualYear[date1.getMonth()]++;
          }
          else if (date.getFullYear() - 1 === date1.getFullYear()) {
            pastYear[date1.getMonth()]++;
          }
        }
      }
      let { data: aux } = await axios.get(direction.API_SUSCRIPTORS_CATALOGUE + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      })
      if (aux) {
        for (let i = 0; i < aux.length; i++) {
          let date1 = new Date(aux[i].createdAt);
          if (date.getFullYear() === date1.getFullYear()) {
            actualYear[date1.getMonth()]++;
          }
          else if (date.getFullYear() - 1 === date1.getFullYear()) {
            pastYear[date1.getMonth()]++;
          }
        }
      }
      var config = {
        type: "line",
        data: {
          labels: newLabel,
          datasets: [
            {
              label: new Date().getFullYear(),
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: actualYear,
              fill: false,
            },
            {
              label: new Date().getFullYear() - 1,
              fill: false,
              backgroundColor: "#fff",
              borderColor: "#fff",
              data: pastYear,

            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "white",
          },
          legend: {
            labels: {
              fontColor: "white",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                  fontColor: "white",
                },
                gridLines: {
                  display: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.3)",
                  zeroLineColor: "rgba(0, 0, 0, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                  fontColor: "white",
                },
                gridLines: {
                  borderDash: [3],
                  borderDashOffset: [3],
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                  zeroLineColor: "rgba(33, 37, 41, 0)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById("line-chart").getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  }

  render() {
    return (
      <>
        {/* <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700"> */}
        {/* <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                  Vista
                </h6>
                <h2 className="text-white text-xl font-semibold">Suscriptores</h2>
              </div>
            </div>
          </div> */}
        <div className="w-full lg:w-12/12">
          <div className="relative w-full mb-3">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </>
    );
  }
}
