import React from "react";

// components

import Genders from "components/Cards/CardGender";
import CardTableGender from "components/Cards/CardTableGender";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <Genders />
        </div>
        <div className="w-full px-4">
          <CardTableGender />
        </div>
       
      </div>
    </>
  );
}
