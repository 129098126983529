import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
import swal from 'sweetalert';
// components
class CardContact extends Component {
  state = {
    id: -1,
    name: '',
    email: '',
    message: '',
    error: {},
    loading: false,
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addContact = (e) => {
    e.preventDefault()
    let error = this.handleError();
    console.log(error);
    if (!error) {

      this.setState({ loading: true });

      let { name, email, message } = this.state;
      let contact = {
        name: name,
        email: email,
        message: message
      }
      const user = JSON.parse(localStorage.getItem('user')) || null;
      if (user !== null) {

        if (this.state.id !== -1) {
          contact['id'] = this.state.id
          console.log(contact);
          console.log(direction.API_CONTACT);
          axios.put(direction.API_CONTACT, contact, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se actualizó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderContactTable()
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        } else {
          axios.post(direction.API_CONTACT, contact, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se insertó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderContactTable()
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        }
      }
    }else{

      this.setState({ loading: false });
      
      swal({
        title: "Upss, Algo salió mal, revise los datos a insertar",
        text: "Haga click en el botón!",
        icon: "error",
      });
    }
  }
  cleanData = () => {
    this.setState({ id: -1, name: '', message: '', email: '' });
  }
  getContact = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_CONTACT}${id}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          this.setState({ id: res.data.id, name: res.data.name, email: res.data.email, message: res.data.message })
        }
      }).catch(e => {
        if (e) {
          swal({
            title: "Upss,Algo salió mal inténtelo mas tarde",
            text: "Haga click en el botón!",
            icon: "error",
          });
        }
      })
    }
  }
  componentDidUpdate() {
    if (this.props.contactId !== -1) {
      this.getContact(this.props.contactId);
      this.props.onRenderContact(-1);
    }
  }
  handleError = () => {
    let error = {}
    let check = false;
    const { name, email, message } = this.state;
    if (!name) {
      error['name'] = "El nombre no debe estar vacío";
      check = true;
    } else if (!name.match(/^[A-Za-z áéíóú]+$/)) {
      error['name'] = "El nombre no debe tener números";
      check = true;
    }
    if ((!email)) {
      error['email'] = "El correo no debe estar vacío";
      check = true;
    } else if (!email.match(/^[\w-\.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}$/)) {
      error['email'] = "Dirección de correo invalida";
      check = true;
    }
    if (!message) {
      check = true;
      error['message'] = "El mensaje no debe estar vacío";
    }
    this.setState({ error })
    return check;

  }
  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Contacto</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null}

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addContact}
              >
              {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Contacto</> : <>Actualizar Contacto</>
                }

              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.name}
                    />
                  </div>
                  {this.state.error.name !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.name}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Correo
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.email}
                    />
                  </div>
                  {this.state.error.email !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.email}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mensaje
                    </label>
                    <input
                      type="text"
                      name="message"
                      onChange={this.setValues}
                      value={this.state.message}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.message !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.message}
                      </span>
                    </div> : null
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </>

    )
  }
}
const mapStateToProps = state => {
  return {
    contactId: state.contactId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRenderContact: (contactId) => dispatch({ type: actionTypes.RENDER_CONTACT, contactId: contactId }),
    onRenderContactTable: () => dispatch({ type: actionTypes.RENDER_CONTACT_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardContact);
