import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
import swal from 'sweetalert';
import { func } from "prop-types";
import Select from 'react-select';
// components

class CardAutor extends Component {

  imagenUrl = "";
  user_selected = "";
  email_selected = "";

  state = {
    id: -1,
    first_name: '',
    last_name: '',
    street: '',
    state: '',
    country: '',
    email: '',
    phone_number: '',
    zip_postal_code: '',
    fax_number: '',
    user_name: '',
    password: '',
    selectedOption: null,
    selectedOption1: null,
    selectedOption2: null,
    type_autors: [],
    File: '',
    picture_address: '',
    biography: '',
    bornDate: '',
    error: {},
    autores: [],
    loading: false,
    libros: [],
    metaTitle: false,
    caractersInMetaTitle: 0,
    caractersInMetaDescription: 0,
    cantidadMetaKeywords: 0,
    inputMetaTitle: '',
    inputMetaKeywords: '',
    textareaMetaDescription: '',
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'inputMetaTitle') {
      this.setState({ caractersInMetaTitle: e.target.value.length });
    } else if (e.target.name === 'textareaMetaDescription') {
      this.setState({ caractersInMetaDescription: e.target.value.length });
    } else if (e.target.name === 'inputMetaKeywords') {
      if (e.target.value.length > 0) {
        if (e.target.value.includes(',')) {
          var arr = e.target.value.split(',');
          this.setState({ cantidadMetaKeywords: arr.length });
        } else {
          this.setState({ cantidadMetaKeywords: 1 });
        }
      } else {
        this.setState({ cantidadMetaKeywords: 0 });
      }
    }
  }

  getLibros = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_PRODUCT + 'all/for-select', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let libros = [];
          for (let value of res.data) {
            libros.push({ label: value.title, value: value.id });
          }
          this.setState({ libros });
        }
      });
    }
  }

  addAutor = async (e) => {
    e.preventDefault()
    let error = this.handleError();
    if (!error) {
      this.setState({ loading: true });
      const {
        first_name,
        last_name,
        street,
        state,
        country,
        email,
        phone_number,
        zip_postal_code,
        fax_number,
        user_name,
        password,
        active,
        selectedOption,
        selectedOption1,
        selectedOption2,
        picture_address,
        biography,
        bornDate,
        inputMetaTitle,
        inputMetaKeywords,
        textareaMetaDescription,
      } = this.state;

      let startDate1 = new Date(bornDate);

      let autor = {
        first_name: first_name,
        last_name: last_name,
        street: street,
        state: state,
        country: country,
        email: email,
        phone_number: phone_number,
        zip_postal_code: zip_postal_code,
        fax_number: fax_number,
        user_name: user_name,
        password: password === "" ? null : password,
        active: active,
        typeAutorId: selectedOption.value,
        biography: biography,
        picture_address: picture_address,
        bornDate: startDate1
      }

      var fullName = first_name.trim().toLowerCase() + ' ' + last_name.trim().toLowerCase();
      var nameResult = fullName.replaceAll(' ', '-');
      // var size = fullName.length;
      // var nameResult = '';
      // var c = '';
      // for (var i = 0; i < size; i++) {
      //   c = fullName.charAt(i);
      //   if (c == ' ') {
      //     nameResult += '-';
      //   } else {
      //     nameResult += c;
      //   }
      // }
      var size = nameResult.length;
      if (nameResult[size - 1] === '-') {
        nameResult = nameResult.substring(0, size - 1);
      }

      autor.lower_full_name = nameResult;

      if (inputMetaTitle === '') {
        autor['metaTitle'] = first_name +" "+ last_name + direction.META_SEPARATOR + direction.META_TITLE;
      } else {
        autor['metaTitle'] = inputMetaTitle;
      }

      if (inputMetaKeywords === '') {
        autor['metaKeywords'] = first_name + " " + last_name;
      } else {
        autor['metaKeywords'] = inputMetaKeywords;
      }

      if (textareaMetaDescription === '') {
        autor['metaDescription'] = biography;
      } else {
        autor['metaDescription'] = textareaMetaDescription;
      }

      const user = JSON.parse(localStorage.getItem('user')) || null;
      if (user !== null) {//usuario logueado en el sistema
        if (this.state.id !== -1) { //para comprobar si va a actualizar
          if (this.state.File !== null && this.state.File !== '') {
            //obtengo el autor          
            axios.get(`${direction.API_AUTOR}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.picture_address != null) {
                let arr = res.data.picture_address.split("/");
                let oldImagen = arr[arr.length - 1];

                //elimino la imagen anterior
                axios.delete(`${direction.API_UPLOAD}image/${oldImagen}`, {
                  params: {
                    name: oldImagen
                  }
                }, {
                  headers: {
                    'x-auth-token': user.accessToken
                  }
                });
                this.imagenUrl = "";
              }
            });
          }
        }

        if (this.state.File !== null && this.state.File !== '') {
          const data = new FormData();
          data.append('imageFile', this.state.File);
          let prefix = 'usuario' + nameResult; //nameResult es el nombre completo separado por guiones
          let { data: result } = await axios.post(direction.API_UPLOAD, data,
            {
              headers: {
                'x-auth-token': user.accessToken
              },
              params: {
                'prefix': prefix
              }
            })
          if (result) {
            autor['picture_address'] = `${direction.API_UPLOAD}image/${prefix}-${result.originalname}`;
          }
        }

        // libros en los que el usuario es escritor
        let librosEscritor = [];
        let select = null;
        if (selectedOption1 != null) {
          select = selectedOption1;
          select.forEach(e => {
            librosEscritor.push({
              "productId": e.value,
              "full_name": first_name + " " + last_name,
              "relation": "escritor",
              "title": e.label
            });
          });
        }

        // libros en los que el usuario es artista visual
        let librosArtista = [];
        if (selectedOption2 != null) {
          select = selectedOption2;
          select.forEach(e => {
            librosArtista.push({
              "productId": e.value,
              "full_name": first_name + " " + last_name,
              "relation": "artista",
              "title": e.label
            });
          });
        }

        if (this.state.id !== -1) {
          autor['id'] = this.state.id
          axios.put(direction.API_AUTOR, { "autor": autor, "librosEscritor": librosEscritor, "librosArtista": librosArtista }, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {

            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se actualizó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData()
              this.props.onRenderAutorTable()
              this.imagenUrl = "";
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        } else {
          axios.post(direction.API_AUTOR, { "autor": autor, "librosEscritor": librosEscritor, "librosArtista": librosArtista }, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se insertó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData()
              this.props.onRenderAutorTable()
              this.imagenUrl = "";
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        }
      }
    } else {

      this.setState({ loading: false });

      swal({
        title: "Upss, Algo salió mal, revise los datos a insertar",
        text: "Haga click en el botón!",
        icon: "error",
      });
    }
  }

  cleanData = () => {
    this.setState({
      id: -1,
      first_name: '',
      last_name: '',
      street: '',
      state: '',
      country: '',
      email: '',
      phone_number: '',
      zip_postal_code: '',
      fax_number: '',
      user_name: '',
      password: '',
      active: false,
      selectedOption: null,
      selectedOption1: null,
      selectedOption2: null,
      picture_address: '',
      biography: '',
      File: '',
      bornDate: '',
      metaTitle: false,
      caractersInMetaTitle: 0,
      caractersInMetaDescription: 0,
      cantidadMetaKeywords: 0,
      inputMetaTitle: '',
      inputMetaKeywords: '',
      textareaMetaDescription: '',
    });

    this.user_selected = '';
    this.email_selected = '';
    this.getTypeAutor();
    document.getElementById('idImgAutor').value = null;
  }

  getAutor = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_AUTOR}${id}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          this.imagenUrl = res.data.picture_address;
          this.user_selected = res.data.user_name;
          this.email_selected = res.data.email;
          var borDate = res.data.bornDate ? res.data.bornDate.split('T')[0] : null;

          axios.get(`${direction.API_PRODUCT_AUTOR}/all/${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res2 => {
            let selectedOption1 = [];
            let selectedOption2 = [];

            res2.data.forEach(pa => {
              if (pa.relation === 'escritor') {
                selectedOption1.push({ label: pa.title, value: pa.productId });
              } else {
                selectedOption2.push({ label: pa.title, value: pa.productId });
              }
            });

            this.setState({
              id: res.data.id,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              street: res.data.street,
              state: res.data.state,
              country: res.data.country,
              email: res.data.email,
              phone_number: res.data.phone_number,
              zip_postal_code: res.data.zip_postal_code,
              fax_number: res.data.fax_number,
              user_name: res.data.user_name,
              password: res.data.password,
              active: res.data.active,
              selectedOption: { label: res.data.type_autor.name, value: res.data.type_autor.id },
              selectedOption1: selectedOption1,
              selectedOption2: selectedOption2,
              biography: res.data.biography,
              picture_address: res.data.picture_address,
              bornDate: borDate,
            });

            if (res.data.metaDescription) {
              this.setState({ textareaMetaDescription: res.data.metaDescription });
              this.setState({ caractersInMetaDescription: res.data.metaDescription.length });
            } else {
              this.setState({ caractersInMetaDescription: 0 });
              this.setState({ textareaMetaDescription: '' });
            }

            if (res.data.metaTitle) {
              this.setState({ inputMetaTitle: res.data.metaTitle });
              this.setState({ caractersInMetaTitle: res.data.metaTitle.length });
            } else {
              this.setState({ caractersInMetaTitle: 0 });
              this.setState({ inputMetaTitle: '' });
            }

            if (res.data.metaKeywords) {
              var metaKeywords = res.data.metaKeywords;
              if (metaKeywords.length > 0) {
                if (metaKeywords.includes(',')) {
                  var arr = metaKeywords.split(',');
                  this.setState({ cantidadMetaKeywords: arr.length });
                  this.setState({ inputMetaKeywords: metaKeywords });
                } else {
                  this.setState({ cantidadMetaKeywords: 1 });
                  this.setState({ inputMetaKeywords: metaKeywords });
                }
              } else {
                this.setState({ cantidadMetaKeywords: 0 });
                this.setState({ inputMetaKeywords: '' });
              }
            } else {
              this.setState({ inputMetaKeywords: '' });
              this.setState({ cantidadMetaKeywords: 0 });
            }
          })
        }
      }).catch(e => {
        if (e) {
          swal({
            title: "Upss,Algo salió mal inténtelo mas tarde",
            text: "Haga click en el botón!",
            icon: "error",
          });
        }
      })
    }
  }

  componentDidUpdate() {
    if (this.props.autorId !== -1) {
      this.getAutor(this.props.autorId);
      this.props.onRenderAutor(-1);
      this.getAutores();
    }
  }

  handleError = () => {
    let error = {}
    let check = false;
    const {
      first_name,
      last_name,
      street,
      state,
      country,
      email,
      zip_postal_code,
      user_name,
      password,
      selectedOption,
      biography,
      phone_number,
      fax_number,
      File,
      bornDate,
    } = this.state;

    if (!first_name) {
      error['first_name'] = "El nombre no debe estar vacío";
      check = true;
    } else if (!first_name.match(/^[A-Za-z áéíóúñ]+$/)) {
      error['first_name'] = "El nombre no debe tener números";
      check = true;
    }
    if (!last_name) {
      error['last_name'] = "El apellido no debe estar vacío";
      check = true;
    } else if (!last_name.match(/^[A-Za-z áéíóúñ]+$/)) {
      error['last_name'] = "El apellido no debe tener números";
      check = true;
    }
    // if (!street) {
    //   error['street'] = "La dirección no debe estar vacía";
    //   check = true;
    // }
    // if (!state) {
    //   error['state'] = "La ciudad no debe estar vacía";
    //   check = true;
    // }
    // else if (!state.match(/^[A-Za-z áéíóú]+$/)) {
    //   error['state'] = "La ciudad no debe tener números";
    //   check = true;
    // }

    // if (!country) {
    //   error['country'] = "El país no debe estar vacío";
    //   check = true;
    // } else if (!country.match(/^[A-Za-z áéíóúñ]+$/)) {
    //   error['country'] = "El país no debe tener números";
    //   check = true;
    // }
    // if (phone_number) {
    // // if (!phone_number.match(/^(\(\+?\d{2,3}\)[\-|\s]?(([\d][\-|\s]?){8})(([\d][\-|\s]?){2})?|(\+?[\d][\-|\s]?){8}(([\d][\-|\s]?){2}(([\d][\-|\s]?){2})?)?)$/)) {
    //   if (!phone_number.match(/^[0-9]+$/)) {
    //     error['phone_number'] = "Compruebe el número de teléfono";
    //     check = true;
    //   }
    // }
    // if (fax_number) {
    //   if (!fax_number.match(/^[0-9]{2,3}-? ?[0-9]{6,7}$/)) {
    //     error['fax_number'] = "El número de fax no debe tener letras";
    //     check = true;
    //   }
    // }

    // if (!zip_postal_code) {
    //   error['zip_postal_code'] = "El código postal no debe estar vacío";
    //   check = true;
    // }
    // if (!email) {
    //   error['email'] = "El correo no debe estar vacío";
    //   check = true;
    // }

    let user_select = this.user_selected;
    if (!user_name) {
      error['user_name'] = "El usuario no debe estar vacío";
      check = true;
    } else {
      this.state.autores.forEach(function (autor) {
        if (user_name === autor.user_name) {
          if (user_select != user_name) {
            error['user_name'] = "El usuario " + user_name + " ya está en uso";
            check = true;
          }
        }
      });
    }

    // if (!password) {
    //   error['password'] = "La contraseña no debe estar vacía";
    //   check = true;
    // }

    let email_selected = this.email_selected;
    if (email) {
      if (!email.match(/[\w-\.]{1,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/)) {
        error['email'] = "Dirección de correo inválida";
        check = true;
      } else {
        this.state.autores.forEach(function (autor) {
          if (email === autor.email) {
            if (email_selected != email) {
              error['email'] = "El correo " + email + " ya está en uso";
              check = true;
            }
          }
        });
      }
    }

    if (selectedOption === null) {
      error['selectedOption'] = "Debe seleccionar una especialidad";
      check = true;
    }
    if (!biography) {
      error['biography'] = "La biografía no debe estar vacía";
      check = true;
    }

    if ((File == null || File == '') && this.state.id == -1) {
      error['File'] = "Debe subir una imagen para el autor";
      check = true;
    } else if (File.name !== undefined && !File.name.toLowerCase().match(/\.(jpg|jpeg|gif|png|webp)$/)) {
      error['File'] = "Solo se admiten archivos con las siguientes extensiones: jpg, jpeg, gif, png, webp";
      check = true;
    }

    if (bornDate === '') {
      error['bornDate'] = "Seleccione una fecha";
      check = true;
    }
    this.setState({ error });
    return check;

  }

  componentDidMount() {
    this.getTypeAutor();
    this.getAutores();
    this.getLibros();
  }

  getAutores() {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_AUTOR + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let autores = [];
          for (let value of res.data) {
            autores.push({ user_name: value.user_name, email: value.email });
          }
          this.setState({ autores });
        }
      });
    }
  }

  getTypeAutor = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_TYPE_AUTORS + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let type_autors = [];
          for (let value of res.data) {
            type_autors.push({ label: value.name, value: value.id });
          }
          this.setState({ type_autors });
        }
      })
    }
  }

  handleSelect = e => {
    this.setState({ selectedOption: { value: e.target.value, label: e.target.label } });
  };

  handleSelect1 = selectedOption1 => {
    this.setState({ selectedOption1 });
  };

  handleSelect2 = selectedOption2 => {
    this.setState({ selectedOption2 });
  };

  handleCheck = (e) => {
    this.setState({ active: !this.state.active });
  }

  handleFile = (e) => {
    let file = e.target.files[0];
    this.setState({ File: file })
  }

  handleCheckMetaTitle = (e) => {
    var fullName = this.state.first_name.trim() + ' ' + this.state.last_name.trim();
    if (fullName != ' ') {
      this.setState({ metaTitle: !this.state.metaTitle });
      if (!this.state.metaTitle) {
        this.setState({ inputMetaTitle: `${fullName} ${direction.META_SEPARATOR} ${direction.META_TITLE}` });
        var size = `${this.state.title} ${direction.META_SEPARATOR} ${direction.META_TITLE}`.length;
        this.setState({ caractersInMetaTitle: size });
      } else {
        this.setState({ inputMetaTitle: '' });
        this.setState({ caractersInMetaTitle: 0 });
      }
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Autor</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null}

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addAutor}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Autor</> : <>Actualizar Autor</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Información del autor
              </h6>
              <div className="flex flex-wrap">

                {/* nombre */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.first_name}
                    />
                  </div>
                  {this.state.error.first_name !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.first_name}
                      </span>
                    </div> : null
                  }
                </div>

                {/* apellidos */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Apellidos
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.last_name}
                    />
                  </div>
                  {this.state.error.last_name !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.last_name}
                      </span>
                    </div> : null
                  }
                </div>

                {/* especialidad */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Especialidad
                    </label>
                    <select
                      name="selectedOption"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={this.handleSelect}
                    >
                      <option value='-1' label="Seleccione una especialidad" selected={this.state.id !== -1 ? false : true}></option>
                      {this.state.type_autors.map(l => {
                        if (this.state.selectedOption !== null)
                          if (l.value === this.state.selectedOption.value)
                            return <option value={l.value} label={l.label} selected="true">{l.label}</option>
                        return <option value={l.value} >{l.label}</option>
                      })}
                    </select>
                  </div>
                  {this.state.error.selectedOption !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.selectedOption}
                      </span>
                    </div> : null
                  }
                </div>

                {/* fecha de nacimiento */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Fecha de nacimiento
                    </label>
                    <input
                      type="date"
                      name="bornDate"
                      value={this.state.bornDate}
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.bornDate !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.bornDate}
                      </span>
                    </div> : null
                  }
                </div>

                {/* usuario */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Usuario
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.user_name}
                    />
                  </div>
                  {this.state.error.user_name !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.user_name}
                      </span>
                    </div> : null
                  }
                </div>

                {/* password */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Contraseña
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.password}
                    />
                  </div>
                  {this.state.error.password !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.password}
                      </span>
                    </div> : null
                  }
                </div>

                {/* imagen */}
                <div className="w-full lg:w-6/12 px-4">

                  <div className="lg:w-6/12 px-4">
                    <label
                      className="inline uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Imagen
                    </label>

                    {this.imagenUrl !== "" ?
                      <img
                        src={this.imagenUrl}
                        alt="..."
                        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-10"
                      ></img> : null
                    }
                  </div>

                  <div className="relative w-full mb-3">
                    <input
                      id="idImgAutor"
                      type="file"
                      name="fileImagen"
                      onChange={this.handleFile}
                      getValue={this.fileInputHandler}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.File !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.File}
                      </span>
                    </div> : null
                  }
                </div>

                {/* activo */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Activo
                    </label>
                    <input
                      type="checkbox"
                      name="active"
                      checked={this.state.active}
                      onChange={this.handleCheck}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={this.state.active}
                    />
                  </div>
                </div>

                {/* autor de */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Autor de
                    </label>
                    <Select
                      name="selectedOption1"
                      options={this.state.libros}
                      onChange={this.handleSelect1}
                      value={this.state.selectedOption1}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      isMulti
                    />
                  </div>
                </div>

                {/* ilustrador de */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Ilustrador de
                    </label>
                    <Select
                      name="selectedOption2"
                      options={this.state.libros}
                      onChange={this.handleSelect2}
                      value={this.state.selectedOption2}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      isMulti
                    />
                  </div>
                </div>

                {/* biografia */}
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Biografía
                    </label>
                    <textarea
                      type="text"
                      name="biography"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.biography}
                      rows="4"
                    ></textarea>
                  </div>
                  {this.state.error.biography !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.biography}
                      </span>
                    </div> : null
                  }
                </div>

              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Información de contacto
              </h6>
              <div className="flex flex-wrap">

                {/* direccion */}
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Dirección
                    </label>
                    <input
                      type="text"
                      name="street"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.street}
                    />
                  </div>
                  {this.state.error.street !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.street}
                      </span>
                    </div> : null
                  }
                </div>

                {/* ciudad */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Ciudad
                    </label>
                    <input
                      type="text"
                      name="state"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.state}
                    />
                  </div>
                  {this.state.error.state !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.state}
                      </span>
                    </div> : null
                  }
                </div>

                {/* pais */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      País
                    </label>
                    <input
                      type="text"
                      name="country"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.country}
                    />
                  </div>
                  {this.state.error.country !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.country}
                      </span>
                    </div> : null
                  }
                </div>

                {/* codigo postal */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Código postal
                    </label>
                    <input
                      type="text"
                      name="zip_postal_code"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.zip_postal_code}
                    />
                  </div>
                  {this.state.error.zip_postal_code !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.zip_postal_code}
                      </span>
                    </div> : null
                  }
                </div>

                {/* correo */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Correo
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.email}
                    />
                  </div>
                  {this.state.error.email !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.email}
                      </span>
                    </div> : null
                  }
                </div>

                {/* numero de telefono */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Número de teléfono
                    </label>
                    <input
                      type="tel"
                      name="phone_number"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.phone_number}
                    />
                  </div>
                  {this.state.error.phone_number !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.phone_number}
                      </span>
                    </div> : null
                  }
                </div>

                {/* numero de fax */}
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Número de fax
                    </label>
                    <input
                      type="text"
                      name="fax_number"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.fax_number}
                    />
                  </div>
                  {this.state.error.fax_number !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.fax_number}
                      </span>
                    </div> : null
                  }
                </div>

              </div>


              <hr className="mt-6 border-b-1 border-blueGray-300" />
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Meta datos
              </h6>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      type="checkbox"
                      name="metaTitle"
                      onChange={this.handleCheckMetaTitle}
                      checked={this.state.metaTitle}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                    <label
                      className="inline uppercase text-blueGray-600 text-xs font-bold mt-2 ml-1"
                      htmlFor="grid-password">
                      Generar título del sitio
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.caractersInMetaTitle}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      60 caracteres máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="inputMetaTitle"
                    placeholder="Especifique un nombre para el usuario"
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.inputMetaTitle}
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mt-5"
                      htmlFor="grid-password"
                    >
                      Palabras claves separadas por coma
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.cantidadMetaKeywords}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      6 palabras o frases máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="inputMetaKeywords"
                    placeholder="palabra clave 1, palabra clave 2, palabra clave 3, ..."
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.inputMetaKeywords}
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mt-5"
                      htmlFor="grid-password"
                    >
                      Descripción
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.caractersInMetaDescription}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      160 caracteres máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <textarea
                  type="text"
                  name="textareaMetaDescription"
                  onChange={this.setValues}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={this.state.textareaMetaDescription}
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    autorId: state.autorId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRenderAutor: (autorId) => dispatch({ type: actionTypes.RENDER_AUTOR, autorId: autorId }),
    onRenderAutorTable: () => dispatch({ type: actionTypes.RENDER_AUTOR_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardAutor);