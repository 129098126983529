import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTableVideo extends Component {
  state = {
    video: [],
    loading: false,
    title: '',
  }

  componentDidMount() {
    this.getVideo();
  }

  getVideo = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    axios.get(`${direction.API_BOOKTRAILER_VIDEO}all`, {
      headers: {
        'x-auth-token': user.accessToken
      }
    }).then(response => {

      let videos = response.data;
      this.setState({ video: response.data });
    },
      error => {

      }
    );
  }
  setValues = (e) => {
    if (e.target.value === '')
      this.getVideo();
    this.setState({ [e.target.name]: e.target.value })
  }


  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {
          axios.get(`${direction.API_BOOKTRAILER_VIDEO}${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            let arr = res.data.picture_address.split("/");
            let oldImagen = arr[arr.length - 1];
            this.eliminarImagen(oldImagen, user);

            arr = res.data.video_address.split("/");
            let oldVideo = arr[arr.length - 1];
            this.eliminarVideo(oldVideo, user);
          });

          axios.delete(direction.API_BOOKTRAILER_VIDEO + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
              this.props.onRenderVideoTable();
            }
          }).catch((e) => {
            if (e)
              swal.fire({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
        }
      })
    }
  }

  eliminarImagen(name, user) {
    axios.delete(`${direction.API_UPLOAD}video/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  eliminarVideo(name, user) {
    axios.delete(`${direction.API_UPLOAD}video/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  renderVideo = (id) => {
    this.props.onRenderVideo(id)
  }

  componentDidUpdate() {
    if (this.props.videos) {
      this.props.onRenderVideoTable();
      this.getVideo();
    }
  }

  render() {
    const column = [
      {
        name: "Título",
        cell: row => row.title
      },
      {
        name: "Poster",
        cell: row => <div>
          <img
            alt="..."
            className="w-full h-20 border-none shadow-lg"
            src={row.picture_address}
          />
        </div>
      },
      {
        name: "Principal",
        cell: row => row.main ? <i class="fas fa-check"></i> : ""
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.renderVideo(row.id)}>
            <i className="fas fa-edit"></i>
          </button>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>
        </React.Fragment>
      }
    ];
    const { video } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de Vieos del Booktrailer
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">

            <DataTable
              data={video}
              columns={column}
              highlightOnHover
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    videoId: state.videoId,
    videos: state.videos
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderVideo: (videoId) => dispatch({ type: actionTypes.RENDER_VIDEO, videoId: videoId }),
    onRenderVideoTable: () => dispatch({ type: actionTypes.RENDER_VIDEO_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTableVideo);