import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTablerole extends Component {
  state = {
    role: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false
  }
  componentDidMount() {
    this.getRoles();
  }
  setPage(page) {
    this.setState({ page: page }, () => {
      this.getRoles();
    });
  }

  getRoles = () => {

    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_ROLE}?page=${page}&per_page=${countPerPage}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(
        response => {
          this.setState({ role: response.data.data, total: response.data.total, loading: true });
        },
        error => {

        }
      );
    }
  }

  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.delete(direction.API_ROLE + `${id}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          swal({
            title: "Se eliminó correctamente",
            text: "Haga click en el botón!",
            icon: "success",
          });
          this.props.onRenderRolesTable();
        }
      }).catch((e) => {
        if (e)
          swal({
            title: "Upps, algo salió mal, inténtelo más tarde",
            text: "Haga click en el botón!",
            icon: "error",
          });
      });
    }
  }
  renderRoles = (id) => {
    this.props.onRenderRoles(id)
  }

  componentDidUpdate() {
    if (this.props.roles) {
      this.props.onRenderRolesTable();
      this.getRoles();
    }
  }
  render() {
    const column = [

      {
        name: "Nombre",
        cell: row => row.name
      },
      {
        name: "Descripción",
        cell: row => row.description
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.renderRoles(row.id)}>
            <i className="fas fa-edit"></i>
          </button>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>
        </React.Fragment>
      }
    ];
    const { role, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de roles
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            {/* Projects table */}

            <DataTable
              data={role}
              columns={column}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    roleId: state.roleId,
    roles: state.roles
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderRoles: (roleId) => dispatch({ type: actionTypes.RENDER_ROLES, roleId: roleId }),
    onRenderRolesTable: () => dispatch({ type: actionTypes.RENDER_ROLES_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTablerole);
