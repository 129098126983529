import React from "react";
import { createPopper } from "@popperjs/core";

const Popover = () => {
    const [popoverShow, setPopoverShow] = React.useState(false);
    const btnRef = React.createRef();
    const popoverRef = React.createRef();
    const openTooltip = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "bottom"
        });
        setPopoverShow(true);
    };
    const closeTooltip = () => {
        setPopoverShow(false);
    };
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full text-center">
                    <i className="fas fa-question-circle text-red-500"
                        onMouseEnter={openTooltip}
                        onMouseLeave={closeTooltip}
                        ref={btnRef}></i>
                    <div
                        className={
                            (popoverShow ? "" : "hidden ") +
                            "bg-lightBlue-600 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                        }
                        ref={popoverRef}
                    >
                        <div>
                            <div
                                className="bg-lightBlue-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
                            >
                                Parámetros de búsqueda
                            </div>
                            <div className="text-white p-3">
                                - Por nombre agregándole "u:" seguido del nombre de usuario: Ejemplo (u:javier90gz1)<br />
                                - Por acción: agregándole "a:" seguida de la acción: Ejemplo (a:INSERTAR)<br />
                                * (Las acciones pueden ser: [INSERTAR, ACTUALIZAR, ELIMINAR, ACCEDER, SALIR DEL SISTEMA])<br />
                                - Por rango de fecha agragándole "rf:(fecha inicial)-(fecha final)", Ejemplo (rf:2021/02/24-2021/02/28)<br />
                                * El formato de la fecha debe ser Año/Mes/Día (YYYY/MM/DD)<br/>
                                - Por las tres separadas por coma. Ejemplo (u:javier90gz1, a:INSERTAR, rf:2021/02/24-2021/02/28)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Popover;

