import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
// components
class CardPack extends Component {

  state = {
    id: -1,
    name: '',
    title: '',
    price: 0,

    isVisibleOption4: false,
    isVisibleOption5: false,
    isVisibleOption6: false,
    isVisibleOption7: false,
    isVisibleOption8: false,
    isVisibleOption9: false,
    isVisibleOption10: false,
    textOption1: '',
    textOption2: '',
    textOption3: '',
    textOption4: '',
    textOption5: '',
    textOption6: '',
    textOption7: '',
    textOption8: '',
    textOption9: '',
    textOption10: '',

    idCotizaTuPack: -1,
    poesiaDescription1: '',
    poesiaDescription2: '',
    poesiaDescription3: '',
    poesiaPrecio1: 0,
    poesiaPrecio2: 0,
    poesiaPrecio3: 0,

    narrativaDescription1: '',
    narrativaDescription2: '',
    narrativaDescription3: '',
    narrativaPrecio1: 0,
    narrativaPrecio2: 0,
    narrativaPrecio3: 0,

    infantilDescription1: '',
    infantilDescription2: '',
    infantilDescription3: '',
    infantilDescription4: '',
    infantilDescription5: '',
    infantilDescription6: '',
    infantilDescription7: '',
    infantilPrecio1: 0,
    infantilPrecio2: 0,
    infantilPrecio3: 0,
    infantilPrecio4: 0,
    infantilPrecio5: 0,
    infantilPrecio6: 0,
    infantilPrecio7: 0,

    autoayudaDescription1: '',
    autoayudaDescription2: '',
    autoayudaDescription3: '',
    autoayudaPrecio1: 0,
    autoayudaPrecio2: 0,
    autoayudaPrecio3: 0,

    bonusDescription1: '',
    bonusDescription2: '',
    bonusDescription3: '',
    bonusPrecio1: 0,
    bonusPrecio2: 0,
    bonusPrecio3: 0,

    error: {},
    loading: false,
  }

  cleanData = () => {
    this.setState({
      id: -1,
      name: '',
      title: '',
      price: 0,

      isVisibleOption4: false,
      isVisibleOption5: false,
      isVisibleOption6: false,
      isVisibleOption7: false,
      isVisibleOption8: false,
      isVisibleOption9: false,
      isVisibleOption10: false,
      textOption1: '',
      textOption2: '',
      textOption3: '',
      textOption4: '',
      textOption5: '',
      textOption6: '',
      textOption7: '',
      textOption8: '',
      textOption9: '',
      textOption10: '',

      idCotizaTuPack: -1,
      poesiaDescription1: '',
      poesiaDescription2: '',
      poesiaDescription3: '',
      poesiaPrecio1: 0,
      poesiaPrecio2: 0,
      poesiaPrecio3: 0,

      narrativaDescription1: '',
      narrativaDescription2: '',
      narrativaDescription3: '',
      narrativaPrecio1: 0,
      narrativaPrecio2: 0,
      narrativaPrecio3: 0,

      infantilDescription1: '',
      infantilDescription2: '',
      infantilDescription3: '',
      infantilDescription4: '',
      infantilDescription5: '',
      infantilDescription6: '',
      infantilDescription7: '',
      infantilPrecio1: 0,
      infantilPrecio2: 0,
      infantilPrecio3: 0,
      infantilPrecio4: 0,
      infantilPrecio5: 0,
      infantilPrecio6: 0,
      infantilPrecio7: 0,

      autoayudaDescription1: '',
      autoayudaDescription2: '',
      autoayudaDescription3: '',
      autoayudaPrecio1: 0,
      autoayudaPrecio2: 0,
      autoayudaPrecio3: 0,

      bonusDescription1: '',
      bonusDescription2: '',
      bonusDescription3: '',
      bonusPrecio1: 0,
      bonusPrecio2: 0,
      bonusPrecio3: 0,

      error: {},
      loading: false,
    });

    this.props.onRenderPackTable();
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    var name = e.target.name;
    if (this.state.error[name] !== undefined) {
      this.state.error[name] = undefined;
    }
  }

  //handle error of inputs
  handleError = () => {
    let error = {};
    const { name, title, price, textOption1, textOption2, textOption3,
      isVisibleOption4, textOption4,
      isVisibleOption5, textOption5,
      isVisibleOption6, textOption6,
      isVisibleOption7, textOption7,
      isVisibleOption8, textOption8,
      isVisibleOption9, textOption9,
      isVisibleOption10, textOption10,
      poesiaDescription1, poesiaPrecio1,
      poesiaDescription2, poesiaPrecio2,
      poesiaDescription3, poesiaPrecio3,
      narrativaDescription1, narrativaPrecio1,
      narrativaDescription2, narrativaPrecio2,
      narrativaDescription3, narrativaPrecio3,
      infantilDescription1, infantilPrecio1,
      infantilDescription2, infantilPrecio2,
      infantilDescription3, infantilPrecio3,
      infantilDescription4, infantilPrecio4,
      infantilDescription5, infantilPrecio5,
      infantilDescription6, infantilPrecio6,
      infantilDescription7, infantilPrecio7,
      autoayudaDescription1, autoayudaPrecio1,
      autoayudaDescription2, autoayudaPrecio2,
      autoayudaDescription3, autoayudaPrecio3,
      bonusDescription1, bonusPrecio1,
      bonusDescription2, bonusPrecio2,
      bonusDescription3, bonusPrecio3
    } = this.state;
    let check = false;

    if (!name) {
      check = true;
      error['name'] = "Debe especificar el nombre del pack";
    }
    if (!title) {
      check = true;
      error['title'] = "Debe especificar el título del pack";
    }
    if (price === 0 || !price) {
      check = true;
      error['price'] = "Debe especificar el precio del pack";
    }
    if (!textOption1) {
      check = true;
      error['textOption1'] = "Debe especificar la opción 1 para el pack";
    }
    if (!textOption2) {
      check = true;
      error['textOption2'] = "Debe especificar la opción 2 para el pack";
    }
    if (!textOption3) {
      check = true;
      error['textOption3'] = "Debe especificar la opción 3 para el pack";
    }
    if (isVisibleOption4 && !textOption4) {
      check = true;
      error['textOption4'] = "Debe especificar la opción 4 para el pack";
    }
    if (isVisibleOption5 && !textOption5) {
      check = true;
      error['textOption5'] = "Debe especificar la opción 5 para el pack";
    }
    if (isVisibleOption6 && !textOption6) {
      check = true;
      error['textOption6'] = "Debe especificar la opción 6 para el pack";
    }
    if (isVisibleOption7 && !textOption7) {
      check = true;
      error['textOption7'] = "Debe especificar la opción 7 para el pack";
    }
    if (isVisibleOption8 && !textOption8) {
      check = true;
      error['textOption8'] = "Debe especificar la opción 8 para el pack";
    }
    if (isVisibleOption9 && !textOption9) {
      check = true;
      error['textOption9'] = "Debe especificar la opción 9 para el pack";
    }
    if (isVisibleOption10 && !textOption10) {
      check = true;
      error['textOption10'] = "Debe especificar la opción 10 para el pack";
    }

    // COTIZA TU PACK
    // POESIA
    if (!poesiaDescription1) {
      check = true;
      error['poesiaDescription1'] = "Especifique la descripción 1"
    }
    if (!poesiaDescription2) {
      check = true;
      error['poesiaDescription2'] = "Especifique la descripción 2"
    }
    if (!poesiaDescription3) {
      check = true;
      error['poesiaDescription3'] = "Especifique la descripción 3"
    }
    if (poesiaPrecio1 === 0 || !poesiaPrecio1) {
      check = true;
      error['poesiaPrecio1'] = " - Especifique el precio";
    }
    if (poesiaPrecio2 === 0 || !poesiaPrecio2) {
      check = true;
      error['poesiaPrecio2'] = " - Especifique el precio";
    }
    if (poesiaPrecio3 === 0 || !poesiaPrecio3) {
      check = true;
      error['poesiaPrecio3'] = " - Especifique el precio";
    }

    // NARRATIVA
    if (!narrativaDescription1) {
      check = true;
      error['narrativaDescription1'] = "Especifique la descripción 1"
    }
    if (!narrativaDescription2) {
      check = true;
      error['narrativaDescription2'] = "Especifique la descripción 2"
    }
    if (!narrativaDescription3) {
      check = true;
      error['narrativaDescription3'] = "Especifique la descripción 3"
    }
    if (narrativaPrecio1 === 0 || !narrativaPrecio1) {
      check = true;
      error['narrativaPrecio1'] = " - Especifique el precio";
    }
    if (narrativaPrecio2 === 0 || !narrativaPrecio2) {
      check = true;
      error['narrativaPrecio2'] = " - Especifique el precio";
    }
    if (narrativaPrecio3 === 0 || !narrativaPrecio3) {
      check = true;
      error['narrativaPrecio3'] = " - Especifique el precio";
    }

    // INFANTIL
    if (!infantilDescription1) {
      check = true;
      error['infantilDescription1'] = "Especifique la descripción 1"
    }
    if (!infantilDescription2) {
      check = true;
      error['infantilDescription2'] = "Especifique la descripción 2"
    }
    if (!infantilDescription3) {
      check = true;
      error['infantilDescription3'] = "Especifique la descripción 3"
    }
    if (!infantilDescription4 && infantilPrecio4 != 0) {
      check = true;
      error['infantilDescription4'] = "Si especifica un precio debe especificar una descripción"
    }
    if (!infantilDescription5 && infantilPrecio5 != 0) {
      check = true;
      error['infantilDescription5'] = "Si especifica un precio debe especificar una descripción"
    }
    if (!infantilDescription6 && infantilPrecio6 != 0) {
      check = true;
      error['infantilDescription6'] = "Si especifica un precio debe especificar una descripción"
    }
    if (!infantilDescription7 && infantilPrecio7 != 0) {
      check = true;
      error['infantilDescription7'] = "Si especifica un precio debe especificar una descripción"
    }

    if (infantilPrecio1 === 0 || !infantilPrecio1) {
      check = true;
      error['infantilPrecio1'] = " - Especifique el precio";
    }
    if (infantilPrecio2 === 0 || !infantilPrecio2) {
      check = true;
      error['infantilPrecio2'] = " - Especifique el precio";
    }
    if (infantilPrecio3 === 0 || !infantilPrecio3) {
      check = true;
      error['infantilPrecio3'] = " - Especifique el precio";
    }
    if (infantilPrecio4 === 0 && infantilDescription4) {
      check = true;
      error['infantilPrecio4'] = " - Si especifica la descripción debe especificar el precio";
    }
    if (infantilPrecio5 === 0 && infantilDescription5) {
      check = true;
      error['infantilPrecio5'] = " - Si especifica la descripción debe especificar el precio";
    }
    if (infantilPrecio6 === 0 && infantilDescription6) {
      check = true;
      error['infantilPrecio6'] = " - Si especifica la descripción debe especificar el precio";
    }
    if (infantilPrecio7 === 0 && infantilDescription7) {
      check = true;
      error['infantilPrecio7'] = " - Si especifica la descripción debe especificar el precio";
    }

    // AUTOAYUDA
    if (!autoayudaDescription1) {
      check = true;
      error['autoayudaDescription1'] = "Especifique la descripción 1"
    }
    if (!autoayudaDescription2) {
      check = true;
      error['autoayudaDescription2'] = "Especifique la descripción 2"
    }
    if (!autoayudaDescription3) {
      check = true;
      error['autoayudaDescription3'] = "Especifique la descripción 3"
    }
    if (autoayudaPrecio1 === 0 || !autoayudaPrecio1) {
      check = true;
      error['autoayudaPrecio1'] = " - Especifique el precio";
    }
    if (autoayudaPrecio2 === 0 || !autoayudaPrecio2) {
      check = true;
      error['autoayudaPrecio2'] = " - Especifique el precio";
    }
    if (autoayudaPrecio3 === 0 || !autoayudaPrecio3) {
      check = true;
      error['autoayudaPrecio3'] = " - Especifique el precio";
    }

    // BONUS
    if (bonusDescription1 && (bonusPrecio1 === 0 || !bonusPrecio1)) {
      check = true;
      error['bonusDescription1'] = "Si especifica una descripción debe especificar el precio"
    }
    if (bonusDescription2 && (bonusPrecio2 === 0 || !bonusPrecio2)) {
      check = true;
      error['bonusDescription2'] = "Si especifica una descripción debe especificar el precio"
    }
    if (bonusDescription3 && (bonusPrecio3 === 0 || !bonusPrecio3)) {
      check = true;
      error['bonusDescription3'] = "Si especifica una descripción debe especificar el precio"
    }

    if (!bonusDescription1 && bonusPrecio1 != 0) {
      check = true;
      error['bonusDescription1'] = "Si especifica un precio debe especificar la descripción"
    }
    if (!bonusDescription2 && bonusPrecio2 != 0) {
      check = true;
      error['bonusDescription2'] = "Si especifica un precio debe especificar la descripción"
    }
    if (!bonusDescription3 && bonusPrecio3 != 0) {
      check = true;
      error['bonusDescription3'] = "Si especifica un precio debe especificar la descripción"
    }

    this.setState({ error });
    return check;
  }

  addPack = async (e) => {
    e.preventDefault();
    let error = this.handleError();
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user != null) {
      if (!error) {

        let { data: result } = await axios.get(`${direction.API_PACK}all`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        });
        
        let flag = false;
        if(this.state.id !== -1){
          if (result.length < 6) {
            flag = true;
          }
        }else if (result.length < 5) {
          flag = true;
        }
        if(flag){
          // inserto el pack
          this.setState({ loading: true });

          const { name, title, price, textOption1, textOption2, textOption3, isVisibleOption4, textOption4,
            isVisibleOption5, textOption5, isVisibleOption6, textOption6, isVisibleOption7, textOption7,
            isVisibleOption8, textOption8, isVisibleOption9, textOption9, isVisibleOption10, textOption10, poesiaDescription1, poesiaPrecio1, poesiaDescription2, poesiaPrecio2,
            poesiaDescription3, poesiaPrecio3, narrativaDescription1, narrativaPrecio1,
            narrativaDescription2, narrativaPrecio2, narrativaDescription3, narrativaPrecio3,
            infantilDescription1, infantilPrecio1, infantilDescription2, infantilPrecio2,
            infantilDescription3, infantilPrecio3, infantilDescription4, infantilPrecio4,
            infantilDescription5, infantilPrecio5, infantilDescription6, infantilPrecio6,
            infantilDescription7, infantilPrecio7, autoayudaDescription1, autoayudaPrecio1,
            autoayudaDescription2, autoayudaPrecio2, autoayudaDescription3, autoayudaPrecio3,
            bonusDescription1, bonusPrecio1, bonusDescription2, bonusPrecio2,
            bonusDescription3, bonusPrecio3, idCotizaTuPack
          } = this.state;

          var lowerName = name.trim().toLowerCase();
          lowerName = lowerName.replaceAll(' ', '');

          let pack = {
            name: name,
            lowerName: lowerName,
            title: title,
            price: price,
            option1: textOption1,
            option2: textOption2,
            option3: textOption3,
            option4: textOption4,
            option5: textOption5,
            option6: textOption6,
            option7: textOption7,
            option8: textOption8,
            option9: textOption9,
            option10: textOption10
          }

          let cotizaTuPack = {
            idCotizaTuPack: idCotizaTuPack,
            poesiaDescription1: poesiaDescription1,
            poesiaDescription2: poesiaDescription2,
            poesiaDescription3: poesiaDescription3,
            poesiaPrecio1: poesiaPrecio1,
            poesiaPrecio2: poesiaPrecio2,
            poesiaPrecio3: poesiaPrecio3,

            narrativaDescription1: narrativaDescription1,
            narrativaDescription2: narrativaDescription2,
            narrativaDescription3: narrativaDescription3,
            narrativaPrecio1: narrativaPrecio1,
            narrativaPrecio2: narrativaPrecio2,
            narrativaPrecio3: narrativaPrecio3,

            infantilDescription1: infantilDescription1,
            infantilDescription2: infantilDescription2,
            infantilDescription3: infantilDescription3,
            infantilDescription4: infantilDescription4,
            infantilDescription5: infantilDescription5,
            infantilDescription6: infantilDescription6,
            infantilDescription7: infantilDescription7,
            infantilPrecio1: infantilPrecio1,
            infantilPrecio2: infantilPrecio2,
            infantilPrecio3: infantilPrecio3,
            infantilPrecio4: infantilPrecio4,
            infantilPrecio5: infantilPrecio5,
            infantilPrecio6: infantilPrecio6,
            infantilPrecio7: infantilPrecio7,

            autoayudaDescription1: autoayudaDescription1,
            autoayudaDescription2: autoayudaDescription2,
            autoayudaDescription3: autoayudaDescription3,
            autoayudaPrecio1: autoayudaPrecio1,
            autoayudaPrecio2: autoayudaPrecio2,
            autoayudaPrecio3: autoayudaPrecio3,

            bonusDescription1: bonusDescription1,
            bonusDescription2: bonusDescription2,
            bonusDescription3: bonusDescription3,
            bonusPrecio1: bonusPrecio1,
            bonusPrecio2: bonusPrecio2,
            bonusPrecio3: bonusPrecio3,
          }

          if (this.state.id !== -1) {
            pack['id'] = this.state.id;
            axios.put(direction.API_PACK, { pack, cotizaTuPack }, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.status === 200) {

                this.setState({ loading: false });

                swal({
                  title: "Se ha actualizado correctamente el pack",
                  text: "Haga click en el botón!",
                  icon: "success",
                });
                this.imagenUrl = "";
                this.pdfUrl = "";
                this.cleanData();
              }
            }).catch((e) => {
              if (e)
                this.setState({ loading: false });
              swal({
                title: "Upps, algo salió mal, inténtelo más tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            });
          } else {
            axios.post(direction.API_PACK, { pack, cotizaTuPack }, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.status === 200) {
                var idPack = res.data.id;

                this.setState({ loading: false });

                swal({
                  title: "Pack insertado correctamente",
                  text: "Haga click en el botón!",
                  icon: "success",
                });
                this.cleanData();
              }
            });
          }
        }else{
          this.setState({ loading: false });
          swal({
            title: "Solo se admiten un máximo de 5 packs",
            text: "Haga click en el botón!",
            icon: "error",
          });
        }
      } else {
        this.setState({ loading: false });
        swal({
          title: "Hay errores en los campos, rellene los campos",
          text: "Haga click en el botón!",
          icon: "error",
        });
      }
    } else {
      this.setState({ loading: false });
      swal({
        title: "Upss, Algo salió mal, revise los datos a insertar",
        text: "Haga click en el botón!",
        icon: "error",
      });
    }
  }

  getPack = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      if (id !== undefined) {
        axios.get(`${direction.API_PACK}${id}`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(res => {
          if (res.status === 200) {
            let pack = res.data.pack;
            let cotizaTuPack = res.data.cotizaTuPack;

            this.setState({
              id: pack.id,
              name: pack.name,
              title: pack.title,
              price: pack.price,
              isVisibleOption4: pack.option4 ? true : false,
              isVisibleOption5: pack.option5 ? true : false,
              isVisibleOption6: pack.option6 ? true : false,
              isVisibleOption7: pack.option7 ? true : false,
              isVisibleOption8: pack.option8 ? true : false,
              isVisibleOption9: pack.option9 ? true : false,
              isVisibleOption10: pack.option10 ? true : false,
              textOption1: pack.option1,
              textOption2: pack.option2,
              textOption3: pack.option3,
              textOption4: pack.option4,
              textOption5: pack.option5,
              textOption6: pack.option6,
              textOption7: pack.option7,
              textOption8: pack.option8,
              textOption9: pack.option9,
              textOption10: pack.option10,

              // COTIZA TU PACK
              idCotizaTuPack: cotizaTuPack.id,
              poesiaDescription1: cotizaTuPack.poesiaDescription1,
              poesiaDescription2: cotizaTuPack.poesiaDescription2,
              poesiaDescription3: cotizaTuPack.poesiaDescription3,
              poesiaPrecio1: cotizaTuPack.poesiaPrecio1,
              poesiaPrecio2: cotizaTuPack.poesiaPrecio2,
              poesiaPrecio3: cotizaTuPack.poesiaPrecio3,

              narrativaDescription1: cotizaTuPack.narrativaDescription1,
              narrativaDescription2: cotizaTuPack.narrativaDescription2,
              narrativaDescription3: cotizaTuPack.narrativaDescription3,
              narrativaPrecio1: cotizaTuPack.narrativaPrecio1,
              narrativaPrecio2: cotizaTuPack.narrativaPrecio2,
              narrativaPrecio3: cotizaTuPack.narrativaPrecio3,

              infantilDescription1: cotizaTuPack.infantilDescription1,
              infantilDescription2: cotizaTuPack.infantilDescription2,
              infantilDescription3: cotizaTuPack.infantilDescription3,
              infantilDescription4: cotizaTuPack.infantilDescription4,
              infantilDescription5: cotizaTuPack.infantilDescription5,
              infantilDescription6: cotizaTuPack.infantilDescription6,
              infantilDescription7: cotizaTuPack.infantilDescription7,
              infantilPrecio1: cotizaTuPack.infantilPrecio1,
              infantilPrecio2: cotizaTuPack.infantilPrecio2,
              infantilPrecio3: cotizaTuPack.infantilPrecio3,
              infantilPrecio4: cotizaTuPack.infantilPrecio4,
              infantilPrecio5: cotizaTuPack.infantilPrecio5,
              infantilPrecio6: cotizaTuPack.infantilPrecio6,
              infantilPrecio7: cotizaTuPack.infantilPrecio7,

              autoayudaDescription1: cotizaTuPack.autoayudaDescription1,
              autoayudaDescription2: cotizaTuPack.autoayudaDescription2,
              autoayudaDescription3: cotizaTuPack.autoayudaDescription3,
              autoayudaPrecio1: cotizaTuPack.autoayudaPrecio1,
              autoayudaPrecio2: cotizaTuPack.autoayudaPrecio2,
              autoayudaPrecio3: cotizaTuPack.autoayudaPrecio3,

              bonusDescription1: cotizaTuPack.bonusDescription1,
              bonusDescription2: cotizaTuPack.bonusDescription2,
              bonusDescription3: cotizaTuPack.bonusDescription3,
              bonusPrecio1: cotizaTuPack.bonusPrecio1,
              bonusPrecio2: cotizaTuPack.bonusPrecio2,
              bonusPrecio3: cotizaTuPack.bonusPrecio3,
            });
          }
        }).catch(e => {
          if (e) {
            swal({
              title: `Upss, Algo salió mal inténtelo mas tarde ${e}`,
              text: "Haga click en el botón!",
              icon: "error",
            });
          }
        })
      }
    }
  }

  showOption = () => {
    if (!this.state.isVisibleOption4) {
      this.setState({ isVisibleOption4: true });
    } else if (!this.state.isVisibleOption5) {
      this.setState({ isVisibleOption5: true });
    } else if (!this.state.isVisibleOption6) {
      this.setState({ isVisibleOption6: true });
    } else if (!this.state.isVisibleOption7) {
      this.setState({ isVisibleOption7: true });
    } else if (!this.state.isVisibleOption8) {
      this.setState({ isVisibleOption8: true });
    } else if (!this.state.isVisibleOption9) {
      this.setState({ isVisibleOption9: true });
    } else if (!this.state.isVisibleOption10) {
      this.setState({ isVisibleOption10: true });
    }
  }

  hideOption = () => {
    if (this.state.isVisibleOption10) {
      this.setState({ isVisibleOption10: false });
      this.setState({ textOption10: '' });
    } else if (this.state.isVisibleOption9) {
      this.setState({ isVisibleOption9: false });
      this.setState({ textOption9: '' });
    } else if (this.state.isVisibleOption8) {
      this.setState({ isVisibleOption8: false });
      this.setState({ textOption8: '' });
    } else if (this.state.isVisibleOption7) {
      this.setState({ isVisibleOption7: false });
      this.setState({ textOption7: '' });
    } else if (this.state.isVisibleOption6) {
      this.setState({ isVisibleOption6: false });
      this.setState({ textOption6: '' });
    } else if (this.state.isVisibleOption5) {
      this.setState({ isVisibleOption5: false });
      this.setState({ textOption5: '' });
    } else if (this.state.isVisibleOption4) {
      this.setState({ isVisibleOption4: false });
      this.setState({ textOption4: '' });
    }
  }

  componentDidUpdate() {
    if (this.props.packId !== -1) {
      this.getPack(this.props.packId);
      this.props.onRenderPack(-1);
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Información del pack</h6>
              <h6 className="text-blueGray-700 text-xl font-bold">Cotiza tu pack</h6>
              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addPack}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Pack</> : <>Actualizar pack</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12">
                  {/* nombre */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nombre
                      </label>
                      <input
                        type="text"
                        name="name"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.name}
                      />
                    </div>
                    {this.state.error.name !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.name}
                        </span>
                      </div> : null
                    }
                  </div>
                  {/* title */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Título
                      </label>
                      <input
                        type="text"
                        name="title"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.title}
                      />
                    </div>
                    {this.state.error.title !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.title}
                        </span>
                      </div> : null
                    }
                  </div>
                  {/* precio */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Precio
                      </label>
                      <input
                        type="number"
                        name="price"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.price}
                      />
                    </div>
                    {this.state.error.price !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.price}
                        </span>
                      </div> : null
                    }
                  </div>
                  {/* opcion 1 */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Opción 1
                      </label>
                      <textarea
                        type="text"
                        name="textOption1"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.textOption1}
                        rows="1"
                      ></textarea>
                      {this.state.error.textOption1 !== undefined ?
                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                          <span className="inline-block align-middle mr-8">
                            {this.state.error.textOption1}
                          </span>
                        </div> : null
                      }
                    </div>
                  </div>
                  {/* opcion 2 */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Opción 2
                      </label>
                      <textarea
                        type="text"
                        name="textOption2"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.textOption2}
                        rows="1"
                      ></textarea>
                      {this.state.error.textOption2 !== undefined ?
                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                          <span className="inline-block align-middle mr-8">
                            {this.state.error.textOption2}
                          </span>
                        </div> : null
                      }
                    </div>
                  </div>
                  {/* opcion 3 */}
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Opción 3
                      </label>
                      <textarea
                        type="text"
                        name="textOption3"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.textOption3}
                        rows="1"
                      ></textarea>
                      {this.state.error.textOption3 !== undefined ?
                        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                          <span className="inline-block align-middle mr-8">
                            {this.state.error.textOption3}
                          </span>
                        </div> : null
                      }
                    </div>
                  </div>
                  {/* opcion 4 */}
                  {this.state.isVisibleOption4 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 4
                        </label>
                        <textarea
                          type="text"
                          name="textOption4"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption4}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption4 && this.state.error.textOption4 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption4}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 5 */}
                  {this.state.isVisibleOption5 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 5
                        </label>
                        <textarea
                          type="text"
                          name="textOption5"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption5}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption5 && this.state.error.textOption5 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption5}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 6 */}
                  {this.state.isVisibleOption6 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 6
                        </label>
                        <textarea
                          type="text"
                          name="textOption6"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption6}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption6 && this.state.error.textOption6 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption6}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 7 */}
                  {this.state.isVisibleOption7 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 7
                        </label>
                        <textarea
                          type="text"
                          name="textOption7"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption7}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption7 && this.state.error.textOption7 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption7}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 8 */}
                  {this.state.isVisibleOption8 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 8
                        </label>
                        <textarea
                          type="text"
                          name="textOption8"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption8}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption8 && this.state.error.textOption8 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption8}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 9 */}
                  {this.state.isVisibleOption9 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 9
                        </label>
                        <textarea
                          type="text"
                          name="textOption9"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption9}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption9 && this.state.error.textOption9 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption9}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  {/* opcion 10 */}
                  {this.state.isVisibleOption10 ?
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Opción 10
                        </label>
                        <textarea
                          type="text"
                          name="textOption10"
                          onChange={this.setValues}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={this.state.textOption10}
                          rows="1"
                        ></textarea>
                        {this.state.isVisibleOption10 && this.state.error.textOption10 !== undefined ?
                          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                            <span className="inline-block align-middle mr-8">
                              {this.state.error.textOption10}
                            </span>
                          </div> : null
                        }
                      </div>
                    </div>
                    : <></>
                  }
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12">
                      <div className="w-full lg:w-12/12 px-4">
                        {!this.state.isVisibleOption10 ?
                          <button
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={this.showOption}
                          >
                            Añadir opción
                          </button>
                          : <></>
                        }
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12">
                      <div className="w-full lg:w-12/12 px-4">
                        {this.state.isVisibleOption4 ?
                          <button
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={this.hideOption}
                          >
                            Eliminar última opción
                          </button>
                          : <></>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                {/* COTIZA TU PACK */}
                <div className="w-full lg:w-6/12">
                  <div class="flex flex-wrap">

                    {/* GENERO POESIA */}
                    {/* genero poesia descripcion 1 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Género Poesía
                          </label>
                          <input
                            type="text"
                            name="poesiaDescription1"
                            placeholder="Descripción 1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaDescription1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia precio 1 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Precio
                          </label>
                          <input
                            type="number"
                            name="poesiaPrecio1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaPrecio1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.poesiaDescription1 !== undefined
                            || this.state.error.poesiaPrecio1 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                <div className="text-center flex justify-between"></div>
                                {this.state.error.poesiaDescription1} {this.state.error.poesiaPrecio1}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* genero poesia descripcion 2 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="poesiaDescription2"
                            placeholder="Descripción 2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaDescription2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="poesiaPrecio2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaPrecio2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.poesiaDescription2 !== undefined
                            || this.state.error.poesiaPrecio2 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.poesiaDescription2} {this.state.error.poesiaPrecio2}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* genero poesia descripcion 3 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="poesiaDescription3"
                            placeholder="Descripción 3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaDescription3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia precio 3 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="poesiaPrecio3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.poesiaPrecio3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero poesia errores 3 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.poesiaDescription3 !== undefined
                            || this.state.error.poesiaPrecio3 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.poesiaDescription3} {this.state.error.poesiaPrecio3}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* GENERO NARRATIVA */}
                    {/* genero narrativa descripcion 1 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Género Narrativa
                          </label>
                          <input
                            type="text"
                            name="narrativaDescription1"
                            placeholder="Descripción 1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaDescription1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa precio 1 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Precio
                          </label>
                          <input
                            type="number"
                            name="narrativaPrecio1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaPrecio1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.narrativaDescription1 !== undefined
                            || this.state.error.narrativaPrecio1 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.narrativaDescription1} {this.state.error.narrativaPrecio1}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa descripcion 2 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="narrativaDescription2"
                            placeholder="Descripción 2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaDescription2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="narrativaPrecio2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaPrecio2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.narrativaDescription2 !== undefined
                            || this.state.error.narrativaPrecio2 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.narrativaDescription2} {this.state.error.narrativaPrecio2}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa descripcion 3 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="narrativaDescription3"
                            placeholder="Descripción 3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaDescription3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa precio 3 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="narrativaPrecio3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.narrativaPrecio3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* genero narrativa errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.narrativaDescription3 !== undefined
                            || this.state.error.narrativaPrecio3 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.narrativaDescription3} {this.state.error.narrativaPrecio3}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* LITERATURA INFANTIL Y JUVENIL */}
                    {/* literatura infantil y juvenil descripcion 1 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Género Literatura Infantil y Juvenil
                          </label>
                          <input
                            type="text"
                            name="infantilDescription1"
                            placeholder="Descripción 1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 1 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Precio
                          </label>
                          <input
                            type="number"
                            name="infantilPrecio1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription1 !== undefined
                            || this.state.error.infantilPrecio1 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription1} {this.state.error.infantilPrecio1}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 2 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription2"
                            placeholder="Descripción 2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription2 !== undefined
                            || this.state.error.infantilPrecio2 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription2} {this.state.error.infantilPrecio2}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 3 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription3"
                            placeholder="Descripción 3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 3 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 3 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription3 !== undefined
                            || this.state.error.infantilPrecio3 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription3} {this.state.error.infantilPrecio3}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 4 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription4"
                            placeholder="Descripción 4"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription4}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 4 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio4"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio4}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 4 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription4 !== undefined
                            || this.state.error.infantilPrecio4 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription4} {this.state.error.infantilPrecio4}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 5 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription5"
                            placeholder="Descripción 5"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription5}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 5 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio5"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio5}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 5 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription5 !== undefined
                            || this.state.error.infantilPrecio5 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription5} {this.state.error.infantilPrecio5}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 6 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription6"
                            placeholder="Descripción 6"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription6}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 6 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio6"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio6}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 6 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription6 !== undefined
                            || this.state.error.infantilPrecio6 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription6} {this.state.error.infantilPrecio6}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* literatura infantil y juvenil descripcion 7 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="infantilDescription7"
                            placeholder="Descripción 7"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilDescription7}
                          />
                        </div>
                      </div>
                    </div>
                    {/* literatura infantil y juvenil precio 7 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="infantilPrecio7"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.infantilPrecio7}
                          />
                        </div>
                      </div>
                    </div>
                    {/* infantil errores 7 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.infantilDescription7 !== undefined
                            || this.state.error.infantilPrecio7 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.infantilDescription7} {this.state.error.infantilPrecio7}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                    {/* TODO */}
                    {/* LIBROS EDUCATIVOS CIENTIFICOS Y DE AUTOAYUDA */}
                    {/* libros educativos cientificos y de autoayuda descripcion 1 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Libros Educativos Científicos y de Autoayuda
                          </label>
                          <input
                            type="text"
                            name="autoayudaDescription1"
                            placeholder="Descripción 1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaDescription1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* libros educativos cientificos y de autoayuda precio 1 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Precio
                          </label>
                          <input
                            type="number"
                            name="autoayudaPrecio1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaPrecio1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* autoayuda errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.autoayudaDescription1 !== undefined
                            || this.state.error.autoayudaPrecio1 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.autoayudaDescription1} {this.state.error.autoayudaPrecio1}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* libros educativos cientificos y de autoayuda descripcion 2 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="autoayudaDescription2"
                            placeholder="Descripción 2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaDescription2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* libros educativos cientificos y de autoayuda precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="autoayudaPrecio2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaPrecio2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* autoayuda errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.autoayudaDescription2 !== undefined
                            || this.state.error.autoayudaPrecio2 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.autoayudaDescription2} {this.state.error.autoayudaPrecio2}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* libros educativos cientificos y de autoayuda descripcion 3 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="autoayudaDescription3"
                            placeholder="Descripción 3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaDescription3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* libros educativos cientificos y de autoayuda precio 3 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="autoayudaPrecio3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.autoayudaPrecio3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* autoayuda errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.autoayudaDescription3 !== undefined
                            || this.state.error.autoayudaPrecio3 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.autoayudaDescription3} {this.state.error.autoayudaPrecio3}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* BONUS */}
                    {/* bonus descripcion 1 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Bonus
                          </label>
                          <input
                            type="text"
                            name="bonusDescription1"
                            placeholder="Descripción 1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusDescription1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus precio 1 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Precio
                          </label>
                          <input
                            type="number"
                            name="bonusPrecio1"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusPrecio1}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus errores 1 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.bonusDescription1 !== undefined
                            || this.state.error.bonusPrecio1 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.bonusDescription1} {this.state.error.bonusPrecio1}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* bonus descripcion 2 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="bonusDescription2"
                            placeholder="Descripción 2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusDescription2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="bonusPrecio2"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusPrecio2}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.bonusDescription2 !== undefined
                            || this.state.error.bonusPrecio2 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.bonusDescription2} {this.state.error.bonusPrecio2}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                    {/* bonus descripcion 3 */}
                    <div className="w-full lg:w-9/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="text"
                            name="bonusDescription3"
                            placeholder="Descripción 3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusDescription3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus precio 2 */}
                    <div className="w-full lg:w-3/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <input
                            type="number"
                            name="bonusPrecio3"
                            onChange={this.setValues}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={this.state.bonusPrecio3}
                          />
                        </div>
                      </div>
                    </div>
                    {/* bonus errores 2 */}
                    <div className="w-full lg:w-12/12">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          {this.state.error.bonusDescription3 !== undefined
                            || this.state.error.bonusPrecio3 !== undefined ?
                            <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                              <span className="inline-block align-middle mr-8">
                                {this.state.error.bonusDescription3}
                              </span>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div >
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    packId: state.packId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderPack: (packId) => dispatch({ type: actionTypes.RENDER_PACK, packId: packId }),
    onRenderPackTable: () => dispatch({ type: actionTypes.RENDER_PACK_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardPack);
