import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";

// components
class CardVideo extends Component {

  image_address = "";

  state = {
    id: -1,
    title: '',
    videoFile: '',
    imageFile: '',
    mainCheck: false,
    error: {},
    loading: false
  }

  cleanData = () => {
    this.setState({
      id: -1,
      title: '',
      videoFile: '',
      imageFile: '',
      mainCheck: false,
      error: {},
      loading: false
    });
    this.props.onRenderVideoTable();
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  //handle error of inputs
  handleError = () => {
    let error = {};
    let check = false;

    const { title, videoFile, imageFile } = this.state;

    if (!title) {
      check = true;
      error['title'] = "Debe introducir un título para el video";
    }

    if ((videoFile === null || videoFile === '') && this.state.id === -1) {
      error['video'] = "Debe especificar el video";
      check = true;
    } else if (videoFile.name !== undefined && !videoFile.name.match(/\.(mp4|avi|mkv)$/)) {
      error['video'] = "Solo se admiten archivos con las siguientes extensiones: mp4, avi, mkv";
      check = true;
    }

    if ((imageFile === null || imageFile === '') && this.state.id === -1) {
      error['image'] = "Debe especificar la imagen para el video";
      check = true;
    } else if (imageFile.name !== undefined && !imageFile.name.toLowerCase().match(/\.(jpg|jpeg)$/)) {
      error['image'] = "Solo se admiten archivos con las siguientes extensiones: jpg, jpeg";
      check = true;
    }

    this.setState({ error });
    return check;
  }


  getVideo = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      if (id !== undefined) {

        axios.get(`${direction.API_BOOKTRAILER_VIDEO}${id}`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(res => {
          if (res.status === 200) {

            this.image_address = res.data.picture_address;

            this.setState({
              id: res.data.id,
              title: res.data.title,
              mainCheck: res.data.main
            });
          }
        }).catch(e => {
          if (e) {
            swal({
              title: `Upss, Algo salió mal inténtelo mas tarde ${e}`,
              text: "Haga click en el botón!",
              icon: "error",
            });
          }
        })
      }
    }
  }

  handleCheck = (e) => {
    this.setState({ mainCheck: !this.state.mainCheck });
  }

  handleFileVideo = (e) => {
    let file = e.target.files[0];
    this.setState({ videoFile: file })
  }


  componentDidUpdate() {
    if (this.props.videoId !== -1) {
      this.getVideo(this.props.videoId);
      this.props.onRenderVideo(-1);
    }
  }

  handleFileImage = (e) => {
    let file = e.target.files[0];
    this.setState({ imageFile: file })
  }

  addVideo = async (e) => {
    e.preventDefault();
    let error = this.handleError();

    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user != null) {
      if (!error) {

        this.setState({ loading: true });
        let { data: result } = await axios.get(`${direction.API_BOOKTRAILER_VIDEO}all`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        });
        console.log(result.length);
        if (result.length < 5) {
          const { title, videoFile, imageFile, mainCheck } = this.state;

          let video = {
            title: title,
            main: mainCheck
          }

          if (this.state.id !== -1) {//para comprobar si va a actualizar
            if (videoFile !== null && videoFile !== '') {
              //obtengo video         
              axios.get(`${direction.API_BOOKTRAILER_VIDEO}${this.state.id}`, {
                headers: {
                  'x-auth-token': user.accessToken
                }
              }).then(res => {
                let arr = res.data.video_address.split("/");
                let oldVideo = arr[arr.length - 1];
                axios.delete(`${direction.API_UPLOAD}video/${oldVideo}`, {
                  params: { name: oldVideo }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
              });
            }//aki acaba

            if (imageFile !== null && imageFile !== '') {
              //get the limage         
              axios.get(`${direction.API_BOOKTRAILER_VIDEO}${this.state.id}`, {
                headers: {
                  'x-auth-token': user.accessToken
                }
              }).then(res => {
                let arr = res.data.picture_address.split("/");
                let oldImage = arr[arr.length - 1];
                axios.delete(`${direction.API_UPLOAD}video/${oldImage}`, {
                  params: { name: oldImage }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
              });
            }//aki acaba
          }

          if (videoFile !== null && videoFile !== '') {
            let data = new FormData();
            data.append('video', videoFile);
            let { data: result } = await axios.post(`${direction.API_UPLOAD}video`, data, {
              headers: {
                'x-auth-token': user.accessToken
              }
            });

            if (result) {
              video['video_address'] = `${direction.API_UPLOAD}image/video/${result.originalname}`;
            }
          }

          if (imageFile !== null && imageFile !== '') {
            let data = new FormData();
            data.append('imageFile', imageFile);
            let { data: result } = await axios.post(`${direction.API_UPLOAD}video/image`, data, {
              headers: {
                'x-auth-token': user.accessToken
              }
            });
            if (result) {
              video['picture_address'] = `${direction.API_UPLOAD}image/video/${result.originalname}`;
            }
          }

          if (this.state.id !== -1) {
            video['id'] = this.state.id;
            axios.put(direction.API_BOOKTRAILER_VIDEO, { "video": video }, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.status === 200) {

                this.setState({ loading: false });

                swal({
                  title: "Se ha actualizado correctamente el video",
                  text: "Haga click en el botón!",
                  icon: "success",
                });
                this.image_address = "";
                this.cleanData();
              }
            }).catch((e) => {
              if (e)

                this.setState({ loading: false });

              swal({
                title: "Upps, algo salió mal, inténtelo más tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            });
          } else {
            axios.post(direction.API_BOOKTRAILER_VIDEO, { "video": video }, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.status === 200) {
                this.setState({ loading: false });
                swal({
                  title: "Fue guardado el video con sus datos",
                  text: "Haga click en el botón!",
                  icon: "success",
                });
                this.cleanData();
              }
            });
          }
        } else {
          swal({
            title: "Solo se admiten 4 videos",
            text: "Haga click en el botón!",
            icon: "error",
          });
        }
      } else {
        console.log(this.state);
        swal({
          title: "Upss, Algo salió mal, revise los datos a insertar",
          text: "Haga click en el botón!",
          icon: "error",
        });
      }
    }
  }


  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Videos del Booktrailer</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addVideo}
              >
                {this.state.loading ? <>Guardando Información</> : <>Guardar Información</>}
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Título
                    </label>
                    <input
                      type="text"
                      name="title"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.title}
                    />
                  </div>
                  {this.state.error.title !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.title}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Video
                    </label>

                    {(this.image_address !== "" && this.image_address !== null) ?
                      <img
                        src={this.image_address}
                        alt="..."
                        className="w-10 h-10 border-2 border-blueGray-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idVideo"
                      type="file"
                      onChange={this.handleFileVideo}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.video !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.video}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Imagen de poster del video
                  </label>

                    {(this.image_address !== "" && this.image_address !== null) ?
                      <img
                        src={this.image_address}
                        alt="..."
                        className="w-10 h-10 border-2 border-blue-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="inImage"
                      type="file"
                      onChange={this.handleFileImage}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.image !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.image}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Video principal
                    </label>
                    <input
                      type="checkbox"
                      name="main"
                      onChange={this.handleCheck}
                      checked={this.state.mainCheck}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    videoId: state.videoId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderVideo: (videoId) => dispatch({ type: actionTypes.RENDER_VIDEO, videoId: videoId }),
    onRenderVideoTable: () => dispatch({ type: actionTypes.RENDER_VIDEO_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardVideo);