import React from "react";

// components

import CardTableLogs from "components/Cards/CardTableLogs";

export default function Logs() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardTableLogs />
        </div>
       
      </div>
    </>
  );
}
