import React from "react";

// components

import Specialty from "components/Cards/CardSpecialty";
import CardTableSpecialty from "components/Cards/CardTableSpecialty";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <Specialty />
        </div>
        <div className="w-full px-4">
          <CardTableSpecialty />
        </div>
       
      </div>
    </>
  );
}
