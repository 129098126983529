import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTablePublicYourBook extends Component {
  state = {
    publications: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false,
    title: '',
  }

  componentDidMount() {
    this.getPublications();
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      if (this.state.search !== '' && this.state.search)
        this.getSearch();
      else
        this.getPublications();
    });
  }

  getPublications = () => {
    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    axios.get(`${direction.API_PUBLICATION}?page=${page}&per_page=${countPerPage}`, {
      headers: {
        'x-auth-token': user.accessToken
      }
    }).then(
      response => {
        this.setState({ publications: response.data.data, total: response.data.total, loading: true });
      },
      error => { }
    );
  }

  setValues = (e) => {
    if (e.target.value === '')
      this.getPublications();
    this.setState({ [e.target.name]: e.target.value })
  }

  getSearch = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      let { title, page, countPerPage } = this.state;
      if (title)
        axios.get(`${direction.API_SEARCH}publication?page=${page}&per_page=${countPerPage}&title=${title}`, {
          headers: {
            'x-auth-token': user.accessToken,
            'Access-Control-Allow-Origin': '*',
          }
        }).then(response => {
          let publications = response.data.data;

          this.setState({ publications: publications, total: response.data.total, loading: true });
        }, error => { }
        )
      else {
        swal({
          title: "Debe agregar un título a la búsqueda",
          text: "Haga click en el botón!",
          icon: "warning",
        });
      }
    }
  }


  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {
          axios.get(`${direction.API_PUBLICATION}${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            let arr = res.data.picture_address.split("/");
            let oldImagen = arr[arr.length - 1];
            this.eliminarImagen(oldImagen, user);

            arr = res.data.manuscript_address.split("/");
            oldImagen = arr[arr.length - 1];

            this.eliminarPdf(oldImagen, user);
          });
          axios.delete(direction.API_PUBLICATION + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
              this.props.onRenderPublicationTable();
            }
          }).catch((e) => {
            if (e)
              swal({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
        }
      })
    }
  }

  eliminarImagen(name, user) {
    axios.delete(`${direction.API_UPLOAD}temp/image/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  eliminarPdf(name, user) {
    axios.delete(`${direction.API_UPLOAD}temp/manuscrito/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  renderPublication = (id) => {
    this.props.onRenderPublication(id)
  }

  componentDidUpdate() {
    if (this.props.publications) {
      this.props.onRenderPublicationTable();
      this.getPublications();
    }
  }

  sendEmail(link_publication, user_name, password) {
    console.log(link_publication);
    console.log(user_name);
    console.log(password);

    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Reenviar correo',
        text: 'Está seguro que desea reenviar el correo asociado a esta publicación?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {
          axios.get(`${direction.API_PUBLICATION}send-email`, {
            params: {
              link_publication: link_publication,
              user_name: user_name,
              password: password
            },
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.data === 'sentemail') {
              swal({
                text: "Correo enviado satisfactoriamente",
                icon: "success",
                timer: '1500'
              });
            } else {
              swal({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
            }
          })
        }
      })
        .catch(e => {
          if (e)
            swal({
              title: "Upps, algo salió mal, inténtelo más tarde",
              icon: "error",
              timer: '1500'
            });
        })
    }
  }

  render() {
    const column = [
      {
        name: "Link de la publicación",
        cell: row => `${row.link_publication}`
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.sendEmail(row.link_publication, row.user_name, row.password)}>
            Reenviar email <i className="fas fa-retweet"></i>
          </button>
        </React.Fragment>
      }
    ];
    const { publications, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de Publicaciones
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">
            {/* Publications table */}

            {/* <input className="w-full mt-4 lg:w-3/12 rounded" type="text" name="title" onChange={this.setValues} placeholder="Buscar por título" />

            <button className="bg-grey-lightest hover:bg-grey-lightest" onClick={this.getSearch}>
              <span className="w-auto flex justify-end items-center p-2 text-grey hover:text-grey-darkest">
                <i className="fas fa-search"></i>
              </span>
            </button> */}
            <DataTable
              data={publications}
              columns={column}
            // highlightOnHover
            // pagination
            // paginationServer
            // paginationTotalRows={total}
            // paginationPerPage={countPerPage}
            // paginationComponentOptions={{
            //   noRowsPerPage: true
            // }}
            // onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    publicationId: state.publicationId,
    publications: state.publications
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderPublication: (publicationId) => dispatch({ type: actionTypes.RENDER_PUBLICATION, publicationId: publicationId }),
    onRenderPublicationTable: () => dispatch({ type: actionTypes.RENDER_PUBLICATION_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTablePublicYourBook);