import React from "react";

// components

import CardProduct from "components/Cards/CardProduct";
import CardTableProduct from "components/Cards/CardTableProduct";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardProduct />
        </div>
        <div className="w-full px-4">
          <CardTableProduct />
        </div>
       
      </div>
    </>
  );
}
