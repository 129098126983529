import React from "react";

// components

import CardPacks from "components/Cards/CardPack";
import CardTablePacks from "components/Cards/CardTablePack";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardPacks />
        </div>
        <div className="w-full px-4">
          <CardTablePacks />
        </div>
      </div>
    </>
  );
}
