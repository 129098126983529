import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
import swal from 'sweetalert';
// components

class CardNews extends Component {

  state = {
    id: -1,
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    active: false,
    error: {},
    loading: false,
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  addNews = (e) => {
    e.preventDefault()
    let error = this.handleError();
    if (!error) {

      this.setState({ loading: true });

      let { title, description, startDate, endDate, active } = this.state;

      let startDate1 = new Date(startDate);
      let endDate1 = new Date(endDate);

      let news = {
        title: title,
        description: description,
        start_date: startDate1,
        end_date: endDate1,
        active: active
      }
      const user = JSON.parse(localStorage.getItem('user')) || null;
      if (user !== null) {
        if (this.state.id !== -1) {
          news['id'] = this.state.id
          axios.put(direction.API_NEWS, news, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se actualizó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderNewsTable()
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        } else {
          axios.post(direction.API_NEWS, news, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(result => {
            if (result.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se insertó corectamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderNewsTable()
            }
          }).catch(e => {
            if (e) {

              this.setState({ loading: false });

              swal({
                title: "Upss,Algo salió mal inténtelo mas tarde",
                text: "Haga click en el botón!",
                icon: "error",
              });
            }
          })
        }
      }
    } else {

      this.setState({ loading: false });

      swal({
        title: "Upss, Algo salió mal, revise los datos a insertar",
        text: "Haga click en el botón!",
        icon: "error",
      });
    }
  }
  
  cleanData = () => {
    this.setState({ id: -1, title: '', description: '', startDate: '', endDate: '', active: false })
  }
  getNewsBYId = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_NEWS}${id}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let date1 = res.data.start_date
          let date2 = res.data.end_date
          this.setState({ id: res.data.id, title: res.data.title, description: res.data.description, active: res.data.active, startDate: date1.split('T')[0], endDate: date2.split('T')[0] })
        }
      }).catch(e => {
        if (e) {
          swal({
            title: "Upss,Algo salió mal inténtelo mas tarde",
            text: "Haga click en el botón!",
            icon: "error",
          });
        }
      })
    }
  }

  componentDidUpdate() {
    if (this.props.newsId !== -1) {
      this.getNewsBYId(this.props.newsId);
      this.props.onRenderNews(-1);
    }
  }

  handleError = () => {
    let error = {}
    let check = false;
    const { title, description, startDate, endDate } = this.state;
    if (!title) {
      error['title'] = "El título no debe estar vacío";
      check = true;
    }
    if (!description) {
      error['description'] = "La descripción no debe estar vacía";
      check = true;
    }
    if (startDate === '') {
      error['startDate'] = "Seleccione una fecha de inicio";
      check = true;
    }
    if (endDate === '') {
      error['endDate'] = "Seleccione una fecha final";
      check = true;
    }

    this.setState({ error })
    return check;

  }
  handleCheck = (e) => {
    this.setState({ active: !this.state.active });
  }
  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Noticias</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addNews}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Noticia</> : <>Actualizar Noticia</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Título
                    </label>
                    <input
                      type="text"
                      name="title"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.title}
                    />
                  </div>
                  {this.state.error.title !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.title}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Descripción
                    </label>
                    <textarea
                      name="description"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.description}
                    />
                  </div>
                  {this.state.error.description !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.description}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Fecha de inicio
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.startDate !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.startDate}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Fecha final
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.endDate !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.endDate}
                      </span>
                    </div> : null
                  }
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Activo
                    </label>
                    <input
                      type="checkbox"
                      name="active"
                      checked={this.state.active}
                      onChange={this.handleCheck}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={this.state.active}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    newsId: state.newsId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRenderNews: (newsId) => dispatch({ type: actionTypes.RENDER_NEWS, newsId: newsId }),
    onRenderNewsTable: () => dispatch({ type: actionTypes.RENDER_NEWS_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardNews);