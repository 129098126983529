export const META_SEPARATOR = ' | ';
export const META_TITLE = 'DMcPherson Editorial';

export const API = "https://catalogo.dmcphersoneditorial.com:3001/api/";
// export const API = "http://localhost:3000/api/";
export const API_SETTINGS = API + 'settings/';
export const API_LOGIN = API + 'login';
export const API_ROLE = API + 'role/';
export const API_USER = API + 'user/';
export const API_CHECK_USER_NAME = API_USER + 'user_name';
export const API_TYPE_AUTORS = API + 'type_autor/';
export const API_NEWS = API + 'news/';
export const API_CONTACT = API + 'contact/';
export const API_GENDER = API + 'product_type/';
export const API_UPLOAD = API + 'upload/';
export const API_AUTOR = API + 'autor/';
export const API_SUSCRIPTORS = API + 'suscription/';
export const API_SUSCRIPTORS_CATALOGUE = API + 'suscription_catalogue/';
export const API_SUSCRIPTORS_BOLETIN = API + 'suscription_boletin/';
export const API_SUSCRIPTORS_HOME = API + 'suscription_home/';
export const API_SUSCRIPTORS_NEWSLETTER = API + 'suscription_home/';
export const API_PRODUCT = API + 'product/';
export const API_PUBLICATION = API + 'publication/';
export const API_LOGS = API + 'activity_log/';
export const API_LOGOUT = API + 'logout/';
export const API_SEARCH = API + 'search/';
export const API_BOLETIN = API + 'boletin/';
export const API_AJUST_NAMES = API + 'ajust_names/';
export const API_RECOMENDED_BOOK = API + 'recomended_book/';
export const API_BOOKTRAILER_VIDEO = API + 'booktrailer_video/';
export const API_PUBLIC_YOUR_BOOK = API + 'public_your_book/';
export const API_PRODUCT_AUTOR = API + 'product_autor/';
export const API_PACK = API + 'pack/';
export const API_COTIZA_TU_PACK = API + 'cotiza_tu_pack/';
export const API_CONTACT_WHATS_APP = API + 'contact_whats_app';