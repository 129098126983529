import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import Tooltips from '../Tooltips/tooltips';
// components

class CardTableLogs extends Component {
  state = {
    role: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false,
    search: ''
  }
  componentDidMount() {
    this.getLogs();
  }
  setPage(page) {
    this.setState({ page: page }, () => {
      this.getLogs();
    });
  }

  getLogs = () => {

    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));

    axios.get(`${direction.API_LOGS}?page=${page}&per_page=${countPerPage}`, {
      headers: {
        'x-auth-token': user.accessToken
      }
    }).then(
      response => {
        this.setState({ logs: response.data.data, total: response.data.total, loading: true });
      },
      error => {

      }
    );
  }
  setValues = (e) => {
    if (!e.target.value)
      this.getLogs();
    this.setState({ [e.target.name]: e.target.value });
  }
  getLogsSearch = () => {
    const { search, page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      let user_name = "";
      let action = "";
      let dateIni = "";
      let dateEnd = "";
      let searchAux = search.split(',');
      let error = false;
      searchAux.forEach(value => {
        console.log(value);
        if (value.indexOf('u:') !== -1) {
          if (value.split(':')[1] !== '' && value.split(':')[1].trim().match(/^[A-Za-z]+$/))
            user_name = value.split(':')[1].trim();
          else
            error = true;
        } else if (value.indexOf('a:') !== -1) {
          if (value.split(':')[1] !== '' && value.split(':')[1].trim().match(/^[A-Za-z]+$/))
            action = value.split(':')[1];
          else
            error = true;
        } else if (value.indexOf('rf:') !== -1) {
          if (value.split(':')[1] !== '') {
            let aux = value.split(':')[1];
            console.log(aux);
            if (aux.split('-')[0] !== "" && (aux.split('-')[0]).trim().match(/^\d{2,4}\/\d{1,2}\/\d{1,2}$/)) {
              console.log(aux.split('-')[0].trim());
              dateIni = new Date(aux.split('-')[0].trim());
              console.log(dateIni);
            } else
              error = true;
            if (aux.split('-')[1] !== "" && (aux.split('-')[1]).trim().match(/^\d{2,4}\/\d{1,2}\/\d{1,2}$/)) {
              console.log(aux.split('-')[1].trim());
              dateEnd = new Date(aux.split('-')[1].trim());
              console.log(dateEnd);
            } else
              error = true;
          } else {
            error = true;
          }
        } else {
          error = true;
        }
      })
      if (error) {
        swal({
          title: `Los parámetros de búsqueda son inválidos, consulte la ayuda si es necesario`,
          text: "Haga click en el botón!",
          icon: "error",
        });
      } else {

        axios.get(`${direction.API_LOGS}search/?page=${page}&per_page=${countPerPage}&user_name=${user_name}&action=${action}&dateIni=${dateIni}&dateEnd=${dateEnd}`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(
          response => {
            this.setState({ logs: response.data.data, total: response.data.total, loading: true });
          },
          error => {

          }
        );
      }
    }
  }
  render() {
    const column = [
      {
        name: "Comentario",
        selector: 'comments',
        sortable: true,
        cell: row => row.comments
      },
      {
        name: "Usuario",
        cell: row => row.userId.user_name,
        selector: 'user_name',
        sortable: true,
      },
      {
        name: "Acción realizada",
        selector: 'activityLogType',
        sortable: true,
        cell: row => row.activityLogType
      },
      {
        name: "Fecha de la acción",
        selector: 'createdAt',
        sortable: true,
        cell: row => row.createdAt
      },
    ];
    const { logs, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de logs
                </h3>
              </div>
              <div className="p-3">
                <Tooltips />
              </div>
            </div>
          </div>
          <div>

          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">
            {/* Projects table */}

            <input className="w-full mt-4 lg:w-3/12 rounded" type="text" name="search" onChange={this.setValues} placeholder="Buscar..." />

            <button className="bg-grey-lightest hover:bg-grey-lightest" onClick={this.getLogsSearch}>
              <span className="w-auto flex justify-end items-center p-2 text-grey hover:text-grey-darkest">
                <i className="fas fa-search"></i>
              </span>
            </button>

            <DataTable
              data={logs}
              columns={column}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CardTableLogs;
