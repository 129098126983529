import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
import Select from 'react-select';

// components
class CardRecomendedBook extends Component {

  state = {
    selectedOption1: null,
    selectedOption2: null,
    selectedOption3: null,
    selectedOption4: null,
    selectedOption5: null,
    books: [],
    error: {},
    loading: false
  }

  componentDidMount() {
    this.getRecomendedBooks();
  }

  handleError = () => {
    let error = {};
    let check = false;

    const {
      selectedOption1,
      selectedOption2,
      selectedOption3,
      selectedOption4,
      selectedOption5
    } = this.state;
    let cont = 0;

    let arr = [];

    if (selectedOption1 != null && selectedOption1.value != -1) {
      arr.push(selectedOption1.value);
    }

    if (selectedOption2 != null && selectedOption2.value != -1) {
      if (!arr.includes(selectedOption2.value)) {
        arr.push(selectedOption2.value);
      } else {
        error['dos'] = "La lectura recomendada 2 ya está seleccionada";
        check = true;
      }
    }

    if (selectedOption3 != null && selectedOption3.value != -1) {
      if (!arr.includes(selectedOption3.value)) {
        arr.push(selectedOption3.value);
      } else {
        error['tres'] = "La lectura recomendada 3 ya está seleccionada";
        check = true;
      }
    }

    if (selectedOption4 != null && selectedOption4.value != -1) {
      if (!arr.includes(selectedOption4.value)) {
        arr.push(selectedOption4.value);
      } else {
        error['cuatro'] = "La lectura recomendada 4 ya está seleccionada";
        check = true;
      }
    }

    if (selectedOption5 != null && selectedOption5.value != -1) {
      if (!arr.includes(selectedOption5.value)) {
        arr.push(selectedOption5.value);
      } else {
        error['cinco'] = "La lectura recomendada 5 ya está seleccionada";
        check = true;
      }
    }

    this.setState({ error });
    return check;
  }

  addRecomendedBooks = async (e) => {
    e.preventDefault();
    let error = this.handleError();
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user != null) {
      if (!error) {
        this.setState({ loading: true });
        // capturo todos los libros seleccionados para insertarlos en la bd
        let books = [];
        if (this.state.selectedOption1 != null) {
          books.push({ id: this.state.selectedOption1.value, title: this.state.selectedOption1.label });
        }
        if (this.state.selectedOption2 != null) {
          books.push({ id: this.state.selectedOption2.value, title: this.state.selectedOption2.label });
        }
        if (this.state.selectedOption3 != null) {
          books.push({ id: this.state.selectedOption3.value, title: this.state.selectedOption3.label });
        }
        if (this.state.selectedOption4 != null) {
          books.push({ id: this.state.selectedOption4.value, title: this.state.selectedOption4.label });
        }
        if (this.state.selectedOption5 != null) {
          books.push({ id: this.state.selectedOption5.value, title: this.state.selectedOption5.label });
        }
        // en books estan guardados los id y nombres de los libros a guardar en la bd
        axios.post(direction.API_RECOMENDED_BOOK, { "books": books }, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(res => {
          if (res.status === 200) {
            this.setState({ loading: false });
            swal({
              title: "Fueron añadidos los libros como lecturas recomendadas.",
              text: "Haga click en el botón!",
              icon: "success",
            });
          }
        });
      }
    }
  }

  getRecomendedBooks = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_PRODUCT + 'all/published', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        
        if (res.status === 200) {
          /* aki hay que consultar la tabla de las lecturas recomendadas para 
          ver cuales fueron las seleccionadas anteriormente y colocarlas seleccionadas */
          axios.get(direction.API_RECOMENDED_BOOK + 'all', {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(rb => {
            if (rb.status === 200) {
              let data = rb.data;
              if (data[0]) {
                let selectedOption1 = { label: data[0].title, value: data[0].id_book };
                this.setState({ selectedOption1 });
              }
              if (data[1]) {
                let selectedOption2 = { label: data[1].title, value: data[1].id_book };
                this.setState({ selectedOption2 });
              }
              if (data[2]) {
                let selectedOption3 = { label: data[2].title, value: data[2].id_book };
                this.setState({ selectedOption3 });
              }
              if (data[3]) {
                let selectedOption4 = { label: data[3].title, value: data[3].id_book };
                this.setState({ selectedOption4 });
              }
              if (data[4]) {
                let selectedOption5 = { label: data[4].title, value: data[4].id_book };
                this.setState({ selectedOption5 });
              }             
            }
          });
          let books = [];
          books.push({ label: 'Seleccione un libro', value: -1 });
          for (let book of res.data) {
            books.push({ label: book.title, value: book.id });
          }
          this.setState({ books });
        }
      });
    }
  }

  handleSelect1 = selectedOption1 => {
    this.setState({ selectedOption1 });
  };

  handleSelect2 = selectedOption2 => {
    this.setState({ selectedOption2 });
  };

  handleSelect3 = selectedOption3 => {
    this.setState({ selectedOption3 });
  };

  handleSelect4 = selectedOption4 => {
    this.setState({ selectedOption4 });
  };

  handleSelect5 = selectedOption5 => {
    this.setState({ selectedOption5 });
  };

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Lecturas Recomendadas</h6>

              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addRecomendedBooks}
              >
                {this.state.loading ? <>Guardando Información</> : <>Guardar Información</>}
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lectura Recomendada 1
                    </label>
                    <Select
                      name="selectedOption1"
                      options={this.state.books}
                      onChange={this.handleSelect1}
                      value={this.state.selectedOption1}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lectura Recomendada 2
                    </label>
                    <Select
                      name="selectedOption2"
                      options={this.state.books}
                      onChange={this.handleSelect2}
                      value={this.state.selectedOption2}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.dos !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.dos}
                      </span>
                    </div> : null
                  }
                </div>


                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lectura Recomendada 3
                    </label>
                    <Select
                      name="selectedOption3"
                      options={this.state.books}
                      onChange={this.handleSelect3}
                      value={this.state.selectedOption3}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.tres !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.tres}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lectura Recomendada 4
                    </label>
                    <Select
                      name="selectedOption4"
                      options={this.state.books}
                      onChange={this.handleSelect4}
                      value={this.state.selectedOption4}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.cuatro !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.cuatro}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Lectura Recomendada 5
                    </label>
                    <Select
                      name="selectedOption5"
                      options={this.state.books}
                      onChange={this.handleSelect5}
                      value={this.state.selectedOption5}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.cinco !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.cinco}
                      </span>
                    </div> : null
                  }
                </div>

              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
export default connect()(CardRecomendedBook);
