import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTableAutor extends Component {
  state = {
    autors: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false,
    first_name: '',
  }
  componentDidMount() {
    this.getAutor();
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      if (this.state.search !== '' && this.state.search)
        this.getSearch();
      else
        this.getAutor();
    });
  }

  getAutor = () => {

    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_AUTOR}?page=${page}&per_page=${countPerPage}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(
        response => {
          this.setState({ autors: response.data.data, total: response.data.total, loading: true });
        },
        error => {
        }
      );
    }
  }
  setValues = (e) => {
    if (e.target.value === '')
      this.getAutor();
    this.setState({ [e.target.name]: e.target.value })
  }

  getSearch = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      let { first_name, page, countPerPage } = this.state;
      if (first_name.match(/^[A-Za-z ]+$/))
        axios.get(`${direction.API_SEARCH}?page=${page}&per_page=${countPerPage}&first_name=${first_name}`, {
          headers: {
            'x-auth-token': user.accessToken,
            'Access-Control-Allow-Origin': '*',
          }
        }).then(response => {
          this.setState({ autors: response.data.data, total: response.data.total, loading: true });
        }, error => {
        })
      else {
        swal({
          title: "Los datos de búsqueda no son válidos",
          text: "Haga click en el botón!",
          icon: "error",
        });
      }
    }
  }

  updatePictureAddress = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_AUTOR}${id}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        var arr = res.data.picture_address.split("/");
        var oldName = arr[arr.length - 1];
        var nameFile = arr[arr.length - 1].split(res.data.email + '-')[1];
        var newName = 'usuario-' + res.data.lower_full_name + '-' + nameFile;

        axios.get(direction.API_UPLOAD + 'rename/images', {
          params: {
            oldName: oldName,
            newName: newName
          }
        }).then(res => { });

        let uri = 'http://localhost:3000/api/upload/image/';
        axios.put(direction.API_AUTOR + 'picture_address',
          {
            id: id,
            picture_address: uri + newName
          }).then(result => {
            this.props.onRenderAutorTable();
          });
      });
    }
  }

  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {

      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {

          axios.get(`${direction.API_AUTOR}${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            let arr = res.data.picture_address.split("/");
            let oldImagen = arr[arr.length - 1];
            this.eliminarImagen(oldImagen, user);
          });

          axios.delete(direction.API_AUTOR + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
              this.props.onRenderAutorTable();
            }
          }).catch((e) => {
            if (e)
              swal.fire({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
        }
      })
    }
  }

  eliminarImagen(name, user) {
    axios.delete(`${direction.API_UPLOAD}image/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  renderAutor = (id) => {
    this.props.onRenderAutor(id)
  }

  componentDidUpdate() {
    if (this.props.autors) {
      this.props.onRenderAutorTable();
      this.getAutor();
    }
  }

  render() {

    const column = [

      {
        name: "Nombre completo",
        cell: row => row.first_name + " " + row.last_name
      },
      {
        name: "Correo",
        cell: row => row.email
      },
      {
        name: "Especialidad",
        cell: row => row.typeAutorId.name
      },
      {
        name: "Foto",
        cell: row => <div>
          <img
            alt="..."
            className="w-full h-20 rounded-full align-middle border-none shadow-lg "
            src={row.picture_address}
          />
        </div>
      },
      {
        name: "Activo",
        cell: row => row.active ? <i class="fas fa-check"></i> : ""
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.renderAutor(row.id)}>
            <i className="fas fa-edit"></i>
          </button>

          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>

          {row.picture_address.includes('@') ?
            <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
              onClick={() => this.updatePictureAddress(row.id)}>
              <i className="fas fa-edit"></i>
            </button>
            : null
          }
          {!row.metaTitle ?
            <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
              onClick={() => this.renderAutor(row.id)}>
              S
            </button>
            : null
          }
        </React.Fragment>
      }
    ];
    const { autors, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de autores
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">
            {/* Projects table */}

            <input className="w-full mt-4 lg:w-3/12 rounded" type="text" name="first_name" onChange={this.setValues} placeholder="Buscar..." />

            <button className="bg-grey-lightest hover:bg-grey-lightest" onClick={this.getSearch}>
              <span className="w-auto flex justify-end items-center p-2 text-grey hover:text-grey-darkest">
                <i className="fas fa-search"></i>
              </span>
            </button>

            <DataTable
              data={autors}
              columns={column}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    autorId: state.autorId,
    autors: state.autors
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRenderAutor: (autorId) => dispatch({ type: actionTypes.RENDER_AUTOR, autorId: autorId }),
    onRenderAutorTable: () => dispatch({ type: actionTypes.RENDER_AUTOR_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTableAutor);