import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTableBoletin extends Component {
  state = {
    boletin: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false,
    title: '',
  }

  componentDidMount() {
    this.getBoletin();
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      if (this.state.search !== '' && this.state.search)
        this.getSearch();
      else
        this.getBoletin();
    });
  }

  getBoletin = () => {
    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    axios.get(`${direction.API_BOLETIN}?page=${page}&per_page=${countPerPage}`, {
      headers: {
        'x-auth-token': user.accessToken
      }
    }).then(
      response => {
        this.setState({ boletin: response.data.data, total: response.data.total, loading: true });
      },
      error => { }
    );
  }

  setValues = (e) => {
    if (e.target.value === '')
      this.getBoletin();
    this.setState({ [e.target.name]: e.target.value })
  }

  getSearch = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      let { title, page, countPerPage } = this.state;
      if (title)
        axios.get(`${direction.API_SEARCH}boletin?page=${page}&per_page=${countPerPage}&title=${title}`, {
          headers: {
            'x-auth-token': user.accessToken,
            'Access-Control-Allow-Origin': '*',
          }
        }).then(response => {
          let boletins = response.data.data;

          this.setState({ boletin: boletins, total: response.data.total, loading: true });
        }, error => { }
        )
      else {
        swal({
          title: "Debe agregar un título a la búsqueda",
          text: "Haga click en el botón!",
          icon: "warning",
        });
      }
    }
  }


  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {
          axios.get(`${direction.API_BOLETIN}${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            console.log('res');
            console.log(res);
            let arr = res.data.picture_address.split("/");
            let oldImagen = arr[arr.length - 1];
            this.eliminarImagen(oldImagen, user);

            arr = res.data.pdf_address.split("/");
            oldImagen = arr[arr.length - 1];

            this.eliminarPdf(oldImagen, user);
          });

          axios.delete(direction.API_BOLETIN + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
              this.props.onRenderBoletinTable();
            }
          }).catch((e) => {
            if (e)
              swal.fire({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
        }
      })
    }
  }

  eliminarImagen(name, user) {
    axios.delete(`${direction.API_UPLOAD}image/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  eliminarPdf(name, user) {
    axios.delete(`${direction.API_UPLOAD}document/${name}`, {
      params: {
        name: name
      }
    }, {
      headers: {
        'x-auth-token': user.accessToken
      }
    });
  }

  renderBoletin = (id) => {
    this.props.onRenderBoletin(id)
  }

  componentDidUpdate() {
    if (this.props.boletins) {
      this.props.onRenderBoletinTable();
      this.getBoletin();
    }
  }

  render() {
    const column = [
      {
        name: "Título",
        cell: row => row.title
      },
      {
        name: "Imagen",
        cell: row => <div>
          <img
            alt="..."
            className="w-full h-20 border-none shadow-lg"
            src={row.picture_address}
          />
        </div>
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.renderBoletin(row.id)}>
            <i className="fas fa-edit"></i>
          </button>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>
        </React.Fragment>
      }
    ];
    const { boletin, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de boletines
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">
            {/* Boletins table */}

            <input className="w-full mt-4 lg:w-3/12 rounded" type="text" name="title" onChange={this.setValues} placeholder="Buscar por título" />

            <button className="bg-grey-lightest hover:bg-grey-lightest" onClick={this.getSearch}>
              <span className="w-auto flex justify-end items-center p-2 text-grey hover:text-grey-darkest">
                <i className="fas fa-search"></i>
              </span>
            </button>
            <DataTable
              data={boletin}
              columns={column}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    boletinId: state.boletinId,
    boletins: state.boletins
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderBoletin: (boletinId) => dispatch({ type: actionTypes.RENDER_BOLETIN, boletinId: boletinId }),
    onRenderBoletinTable: () => dispatch({ type: actionTypes.RENDER_BOLETIN_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTableBoletin);