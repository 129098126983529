import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTablePack extends Component {
  state = {
    pack: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false,
    title: '',
  }

  componentDidMount() {
    this.getPack();
  }

  getPack = () => {
    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    axios.get(`${direction.API_PACK}all`, {
      headers: {
        'x-auth-token': user.accessToken
      }
    }).then(
      response => {
        this.setState({ pack: response.data, loading: true });
      },
      error => { }
    );
  }

  setValues = (e) => {
    if (e.target.value === '')
      this.getPack();
    this.setState({ [e.target.name]: e.target.value })
  }

  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {
          axios.delete(direction.API_PACK + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
              this.props.onRenderPackTable();
            }
          }).catch((e) => {
            if (e)
              swal.fire({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
        }
      })
    }
  }

  renderPack = (id) => {
    this.props.onRenderPack(id);
  }

  componentDidUpdate() {
    if (this.props.packs) {
      this.props.onRenderPackTable();
      this.getPack();
    }
  }

  render() {
    const column = [
      {
        name: "Nombre",
        cell: row => row.name
      },
      {
        name: "Título",
        cell: row => row.title
      },
      {
        name: "Precio",
        cell: row => row.price
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.renderPack(row.id)}>
            <i className="fas fa-edit"></i>
          </button>
          <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => this.delete(row.id)}>
            <i className="fas fa-trash"></i>
          </button>
        </React.Fragment>
      }
    ];
    const { pack } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de Packs, solo se admiten 5 packs
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto px-4 p-4">
            <DataTable
              data={pack}
              columns={column}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    packId: state.packId,
    packs: state.packs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderPack: (packId) => dispatch({ type: actionTypes.RENDER_PACK, packId: packId }),
    onRenderPackTable: () => dispatch({ type: actionTypes.RENDER_PACK_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTablePack);