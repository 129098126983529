import React from "react";

// components

import News from "components/Cards/CardNews";
import CardTableNews from "components/Cards/CardTableNews";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <News />
        </div>
        <div className="w-full px-4">
          <CardTableNews />
        </div>
       
      </div>
    </>
  );
}
