import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as direction from '../../direction/direction';
import swal from 'sweetalert';
import * as actionTypes from '../../store/actions';
import { connect } from "react-redux";
// components

class CardTableSpecialty extends Component {

  state = {
    specialties: [],
    total: 0,
    countPerPage: 10,
    page: 1,
    loading: false
  }
  componentDidMount() {
    this.getSpecialties();
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      this.getSpecialties();
    });
  }

  getSpecialties = () => {

    const { page, countPerPage } = this.state;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(`${direction.API_TYPE_AUTORS}?page=${page}&per_page=${countPerPage}`, {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(
        response => {
          this.setState({ specialties: response.data.data, total: response.data.total, loading: true });
        },
        error => {

        }
      );
    }
  }

  delete = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      swal({
        title: 'Eliminar',
        text: 'Está seguro que desea eliminar este registro?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(result => {
        if (result) {

          axios.delete(direction.API_TYPE_AUTORS + `${id}`, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {
              swal({
                text: "Se eliminó correctamente",
                icon: "success",
                timer: '1500'
              });
            }
          }).catch((e) => {
            if (e)
              swal.fire({
                title: "Upps, algo salió mal, inténtelo más tarde",
                icon: "error",
                timer: '1500'
              });
          });
          this.getSpecialties();
        }
      })
    }
  }
  renderSpecialty = (id) => {
    this.props.onRenderSpecialty(id)
  }

  componentDidUpdate() {
    if (this.props.specialties) {
      this.props.onRenderSpecialtyTable();
      this.getSpecialties();
    }
  }
  render() {
    const column = [

      {
        name: "Nombre",
        cell: row => row.name
      },
      {
        name: "Descripción",
        cell: row => row.description
      },
      {
        name: 'Operaciones',
        cell: row => <React.Fragment>
          {row.name !== 'Escritor' && row.name !== 'Artista Visual' && row.name !== 'Ambos' && row.name !== 'Usuario' && row.name !== 'Ilustrador' ?
            <>
              <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                onClick={() => this.renderSpecialty(row.id)}>
                <i className="fas fa-edit"></i>
              </button>
              <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                onClick={() => this.delete(row.id)}>
                <i className="fas fa-trash"></i>
              </button>
            </> : null
          }
        </React.Fragment>
      }
    ];
    const { specialties, total, countPerPage } = this.state;

    return (
      <>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    "font-semibold text-lg text-blueGray-700"
                  }
                >
                  Tabla de especialidades
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            {/* Projects table */}

            <DataTable
              data={specialties}
              columns={column}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={page => this.setPage(page)}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    specialtyId: state.specialtyId,
    specialties: state.specialties
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onRenderSpecialty: (specialtyId) => dispatch({ type: actionTypes.RENDER_SPECIALTY, specialtyId: specialtyId }),
    onRenderSpecialtyTable: () => dispatch({ type: actionTypes.RENDER_SPECIALTY_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardTableSpecialty);