import React, { Component } from "react";
import axios from 'axios';
import * as direction from '../../direction/direction';
import * as actionTypes from '../../store/actions';
import swal from 'sweetalert';
import { connect } from "react-redux";
import Select from 'react-select';
import { async } from "q";
// components
class CardProduct extends Component {

  imagenUrl = "";
  pdfUrl = "";
  BookUrl = ""

  state = {
    id: -1,
    title: '',
    price: 0,
    priceTapaBlanda: 0,
    picture_address: '',
    published: true,
    sendNotify: false,
    genders: [],
    selectedOption: null,
    synopsis: '',
    startDatePromotion: '',
    endDatePromotion: '',
    discount: 0,
    activePromotion: false,
    autors: [],
    ilustrators: [],
    selectedOption1: null,
    selectedOption2: null,
    linkCompraEBook: '',
    linkCompraTapaBlanda: '',
    FilePdf: '',
    BookPdf: '',
    File: '',
    error: {},
    loading: false,
    metaTitle: false,
    caractersInMetaTitle: 0,
    caractersInMetaDescription: 0,
    cantidadMetaKeywords: 0,
    inputMetaTitle: '',
    inputMetaKeywords: '',
    textareaMetaDescription: '',
  }

  setValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'inputMetaTitle') {
      this.setState({ caractersInMetaTitle: e.target.value.length });
    } else if (e.target.name === 'textareaMetaDescription') {
      this.setState({ caractersInMetaDescription: e.target.value.length });
    } else if (e.target.name === 'inputMetaKeywords') {
      if (e.target.value.length > 0) {
        if (e.target.value.includes(',')) {
          var arr = e.target.value.split(',');
          this.setState({ cantidadMetaKeywords: arr.length });
        } else {
          this.setState({ cantidadMetaKeywords: 1 });
        }
      } else {
        this.setState({ cantidadMetaKeywords: 0 });
      }
    }
  }

  //handle error of inputs
  handleError = () => {
    let error = {};
    const { title, synopsis, price, priceTapaBlanda, selectedOption, startDatePromotion, endDatePromotion, discount, selectedOption1, selectedOption2, activePromotion, File, FilePdf, linkCompraEBook, linkCompraTapaBlanda } = this.state;
    let check = false;
    if (!title) {
      check = true;
      error['title'] = "Debe introducir un título";
    }
    if (!synopsis) {
      check = true;
      error['synopsis'] = "la sinopsis no debe estar vacía";
    }

    if (!price) {
      check = true;
      error['price'] = "El precio no debe estar vacío";
    }

    if (!priceTapaBlanda) {
      check = true;
      error['priceTapaBlanda'] = "El precio de tapa blanda no debe estar vacío";
    }

    if (!linkCompraEBook) {
      check = true;
      error['linkCompraEBook'] = "El link de compra E-Book en Amazon no debe estar vacío";
    }

    if (!linkCompraTapaBlanda) {
      check = true;
      error['linkCompraTapaBlanda'] = "El link de compra de portada de papel en Amazon no debe estar vacío";
    }

    if (selectedOption === null) {
      check = true;
      error['gender'] = "Debe seleccionar un género";
    }

    // if (selectedOption1 === null) {
    //   check = true;
    //   error['autors'] = "Debe seleccionar al menos un autor";
    // }

    // if (selectedOption2 === null) {
    //   check = true;
    //   error['autors'] = "Debe seleccionar al menos un artista visual";
    // }

    if (activePromotion) {
      if (discount === 0) {
        check = true;
        error['discount'] = "El descuento debe ser mayor que 0";
      }
      if (startDatePromotion === '') {
        check = true;
        error['startDatePromotion'] = "La fecha no debe estar vacía";
      }
      if (endDatePromotion === '') {
        check = true;
        error['endDatePromotion'] = "La fecha no debe estar vacía";
      }
    }

    if ((File === null || File === '') && this.state.id === -1) {
      error['image'] = "Debe especificar una imagen para el libro";
      check = true;
    } else if (File.name !== undefined && !File.name.toLowerCase().match(/\.(jpg|jpeg|gif|png|webp)$/)) {
      error['image'] = "Solo se admiten archivos con las siguientes extensiones: jpg, jpeg, gif, png, webp";
      check = true;
    }

    if ((FilePdf === null || FilePdf === '') && this.state.id === -1) {
      error['pdf'] = "Debe especificar un fragmento para el libro en pdf";
      check = true;
    } else if (FilePdf.name !== undefined && !FilePdf.name.match(/\.(pdf|PDF)$/)) {
      error['pdf'] = "Solo se admiten archivos de tipo PDF";
      check = true;
    }

    this.setState({ error })
    return check;
  }

  getGender = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_GENDER + 'all', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {
        if (res.status === 200) {
          let genders = [];
          for (let value of res.data) {
            genders.push({ label: value.name, value: value.id });
          }
          this.setState({ genders });
        }
      })
    }
  }

  getAutor = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_AUTOR + 'todos', {
        headers: {
          'x-auth-token': user.accessToken
        },
        params: {
          'criterio': 'Escritor'
        }
      }).then(res => {
        if (res.status === 200) {
          let autors = [];
          for (let value of res.data) {
            if (value.active) {
              autors.push({ label: value.first_name + " " + value.last_name, value: value.id });
            }
          }
          this.setState({ autors });
        }
      });
    }
  }

  getIlustrador = () => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      axios.get(direction.API_AUTOR + 'artistas-visuales', {
        headers: {
          'x-auth-token': user.accessToken
        }
      }).then(res => {

        if (res.status === 200) {
          let ilustrators = [];
          for (let value of res.data) {
            ilustrators.push({ label: value.first_name + " " + value.last_name, value: value.id });
          }
          this.setState({ ilustrators });
        }
      });
    }
  }

  componentDidMount() {
    this.getGender();
    this.getAutor();
    this.getIlustrador();
  }

  handleSelect = e => {
    this.setState({ selectedOption: { value: e.target.value, label: e.target.label } });
  };

  handleSelect1 = selectedOption1 => {
    this.setState({ selectedOption1 });
  };

  handleSelect2 = selectedOption2 => {
    this.setState({ selectedOption2 });
  };

  handleCheck = (e) => {
    this.setState({ published: !this.state.published });
  }

  handleCheck2 = (e) => {
    this.setState({ sendNotify: !this.state.sendNotify });
  }

  handleCheckP = (e) => {
    this.setState({ activePromotion: !this.state.activePromotion });
  }

  handleCheckMetaTitle = (e) => {
    if (this.state.title != '') {
      this.setState({ metaTitle: !this.state.metaTitle });
      if (!this.state.metaTitle) {
        this.setState({ inputMetaTitle: `${this.state.title} ${direction.META_SEPARATOR} ${direction.META_TITLE}` });
        var size = `${this.state.title} ${direction.META_SEPARATOR} ${direction.META_TITLE}`.length;
        this.setState({ caractersInMetaTitle: size });
      } else {
        this.setState({ inputMetaTitle: '' });
        this.setState({ caractersInMetaTitle: 0 });
      }
    }
  }

  addProduct = async (e) => {
    e.preventDefault();
    let error = this.handleError();
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user != null) {
      if (!error) {

        this.setState({ loading: true });

        const {
          title, synopsis, price, priceTapaBlanda, published,
          selectedOption, startDatePromotion,
          endDatePromotion, discount, selectedOption1, selectedOption2,
          File, FilePdf, BookPdf, activePromotion, linkCompraEBook,
          linkCompraTapaBlanda, sendNotify, inputMetaTitle,
          inputMetaKeywords, textareaMetaDescription
        } = this.state;

        let product = {
          title: title,
          synopsis: synopsis,
          price: price,
          priceTapaBlanda: priceTapaBlanda,
          published: published,
          productTypeId: selectedOption.value,
          linkCompraEBook: linkCompraEBook,
          linkCompraTapaBlanda: linkCompraTapaBlanda
        }

        var titleLowerCase = title.trim().toLowerCase();
        titleLowerCase = titleLowerCase.replaceAll('*', '');
        titleLowerCase = titleLowerCase.replaceAll('?', '');
        titleLowerCase = titleLowerCase.replaceAll('¿', '');
        titleLowerCase = titleLowerCase.replaceAll('!', '');
        titleLowerCase = titleLowerCase.replaceAll('¡', '');
        titleLowerCase = titleLowerCase.trim();
        var titleResult = titleLowerCase.replaceAll(' ', '-');
        // var size = titleLowerCase.length;
        // var titleResult = '';
        // var titlePdf = '';
        // var c = '';
        // for (var i = 0; i < size; i++) {
        //   c = titleLowerCase.charAt(i);
        //   if (c === ' ') {
        //     titleResult += '-';
        //   } else {
        //     titleResult += c;
        //     titlePdf += c;
        //   }
        // }
        product.lowerTitle = titleResult;
        product.views = 0;
        

        if (inputMetaTitle === '') {
          product['metaTitle'] = title + direction.META_SEPARATOR + direction.META_TITLE;
        } else {
          product['metaTitle'] = inputMetaTitle;
        }

        if (inputMetaKeywords === '') {
          product['metaKeywords'] = title;
        } else {
          product['metaKeywords'] = inputMetaKeywords;
        }

        if (textareaMetaDescription === '') {
          product['metaDescription'] = synopsis;
        } else {
          product['metaDescription'] = textareaMetaDescription;
        }

        // this is the autors
        let autors = [];
        let select = null;
        if (selectedOption1 != null) {
          let select = selectedOption1;
          select.forEach(e => {
            autors.push({ "autorId": e.value, "full_name": e.label, "title": title });
          });
        }

        // this is the ilustrators
        let ilustrators = [];
        if (selectedOption2 != null) {
          select = selectedOption2;
          select.forEach(e => {
            ilustrators.push({ "autorId": e.value, "full_name": e.label, "title": title });
          });
        }

        if (this.state.id !== -1) {//para comprobar si va a actualizar
          if (File !== null && File !== '') {
            //obtengo el libro         
            axios.get(`${direction.API_PRODUCT}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.picture_address != null) {
                let arr = res.data.picture_address.split("/");
                let oldImagen = arr[arr.length - 1];
                //elimino la imagen anterior
                axios.delete(`${direction.API_UPLOAD}image/${oldImagen}`, {
                  params: {
                    name: oldImagen
                  }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
                this.imagenUrl = "";
              }
            });
          }//aki acaba

          if (FilePdf !== null && FilePdf !== '') {

            //obtengo el libro         
            axios.get(`${direction.API_PRODUCT}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.book_fragment != null) {
                var arr = res.data.book_fragment.split("/");
                let oldPdf = arr[arr.length - 1];

                //elimino el pdf anterior
                axios.delete(`${direction.API_UPLOAD}document/${oldPdf}`, {
                  params: {
                    name: oldPdf
                  }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
                this.pdfUrl = "";
              }
            });
          }

          if (BookPdf !== null && BookPdf !== '') {

            //obtengo el libro         
            axios.get(`${direction.API_PRODUCT}${this.state.id}`, {
              headers: {
                'x-auth-token': user.accessToken
              }
            }).then(res => {
              if (res.data.book_pdf != null) {
                var arr = res.data.book_pdf.split("/");
                let oldPdf = arr[arr.length - 1];

                //elimino el pdf anterior
                axios.delete(`${direction.API_UPLOAD}document/${oldPdf}`, {
                  params: {
                    name: oldPdf
                  }
                }, {
                    headers: {
                      'x-auth-token': user.accessToken
                    }
                  });
                this.BookUrl = "";
              }
            });
          }
        }

        if (File !== null && File !== '') {
          const data = new FormData();
          data.append('imageFile', File);
          var prefix = 'libro-' + titleResult; //titleResult es el nombre completo separado por guiones
          let { data: result } = await axios.post(direction.API_UPLOAD, data, {
            headers: {
              'x-auth-token': user.accessToken
            },
            params: {
              'prefix': prefix
            }
          })
          if (result) {
            product['picture_address'] = `${direction.API_UPLOAD}image/${prefix}-${result.originalname}`;
          }
        }

        if (FilePdf !== null && FilePdf !== '') {
          const data = new FormData();
          data.append('docsFile', FilePdf);
          var prefix = 'libro-' + titleResult;
          let { data: result } = await axios.post(`${direction.API_UPLOAD}file`, data, {
            headers: {
              'x-auth-token': user.accessToken
            },
            params: {
              'prefix': prefix
            }
          })
          if (result) {
            product['book_fragment'] = `${direction.API_UPLOAD}documents/${prefix}-${result.originalname}`;
          }
        }

        if (BookPdf !== null && BookPdf !== '') {
          const data = new FormData();
          data.append('docsFile', BookPdf);
          var prefix = 'libroCompleto-' + titleResult;
          let { data: result } = await axios.post(`${direction.API_UPLOAD}file`, data, {
            headers: {
              'x-auth-token': user.accessToken
            },
            params: {
              'prefix': prefix
            }
          })
          if (result) {
            product['book_pdf'] = `${direction.API_UPLOAD}documents/${prefix}-${result.originalname}`;
          }
        }

        if (activePromotion) {
          let startDate1 = new Date(startDatePromotion);
          let endDate1 = new Date(endDatePromotion);

          product.startDatePromotion = startDate1;
          product.endDatePromotion = endDate1;
          product.discount = discount;
        } else {
          product.startDatePromotion = '2000-01-01';
          product.endDatePromotion = '2000-01-01';
          product.discount = 0;
        }
        if (this.state.id !== -1) {
          product['id'] = this.state.id;

          axios.put(direction.API_PRODUCT, { "product": product, "autors": autors, "ilustrators": ilustrators, "sendNotify": sendNotify }, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Se ha actualizado correctamente el producto",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderProductsTable();
              this.imagenUrl = "";
              this.pdfUrl = "";
              this.BookUrl = "";
            }
          }).catch((e) => {
            if (e)

              this.setState({ loading: false });

            swal({
              title: "Upps, algo salió mal, inténtelo más tarde",
              text: "Haga click en el botón!",
              icon: "error",
            });
          });
        } else {
          axios.post(direction.API_PRODUCT, { "product": product, "autors": autors, "ilustrators": ilustrators, "sendNotify": sendNotify }, {
            headers: {
              'x-auth-token': user.accessToken
            }
          }).then(res => {
            if (res.status === 200) {

              this.setState({ loading: false });

              swal({
                title: "Producto insertado correctamente",
                text: "Haga click en el botón!",
                icon: "success",
              });
              this.cleanData();
              this.props.onRenderProductsTable();
              this.imagenUrl = "";
              this.pdfUrl = "";
              this.BookUrl = "";
            }
          });
        }
      } else {

        this.setState({ loading: false });

        swal({
          title: "Upss, Algo salió mal, revise los datos a insertar",
          text: "Haga click en el botón!",
          icon: "error",
        });
      }
    }
  }

  cleanData = () => {
    this.setState({
      id: -1,
      title: '',
      price: 0,
      priceTapaBlanda: 0,
      picture_address: '',
      published: true,
      sendNotify: false,
      genders: [],
      selectedOption: null,
      selectedOption1: null,
      selectedOption2: null,
      synopsis: '',
      startDatePromotion: '',
      endDatePromotion: '',
      discount: 0,
      activePromotion: false,
      linkCompraEBook: '',
      linkCompraTapaBlanda: '',
      File: '',
      FilePdf: '',
      metaTitle: false,
      caractersInMetaTitle: 0,
      caractersInMetaDescription: 0,
      cantidadMetaKeywords: 0,
      inputMetaTitle: '',
      inputMetaKeywords: '',
      textareaMetaDescription: '',
    });
    this.getGender();
    this.getAutor();
    this.getIlustrador();

    document.getElementById('idImgLibro').value = null;
    document.getElementById('idPdfLibro').value = null;
  }

  getProduct = (id) => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user !== null) {
      if (id !== undefined) {
        let idEscritor = -1;
        let idAmbos = -1;
        // capturo el id del tipo de autor ilustrador
        axios.get(`${direction.API_TYPE_AUTORS}id_escritor`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(result => {
          if (result.data) {
            idEscritor = result.data.id;
          }
        });
        // capturo el id del tipo de autor ambos
        axios.get(`${direction.API_TYPE_AUTORS}id_ambos`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(result => {
          if (result.data) {
            idAmbos = result.data.id;
          }
        });

        axios.get(`${direction.API_PRODUCT}${id}`, {
          headers: {
            'x-auth-token': user.accessToken
          }
        }).then(res => {

          if (res.status === 200) {
            let productTypeId = res.data.productTypeId;
            let selectedOption1 = [];
            let selectedOption2 = [];
            this.imagenUrl = res.data.picture_address;
            this.pdfUrl = res.data.book_fragment;
            this.BookUrl = res.data.book_pdf;

            res.data.product_autors.forEach(pa => {
              if (pa.relation === 'escritor') {
                res.data.autors.forEach(a => {
                  if (a.id === pa.autorId) {
                    selectedOption1.push({ label: a.first_name + " " + a.last_name, value: a.id });
                  }
                });
              } else if (pa.relation === 'artista') {
                if (pa.full_name != null) {
                  selectedOption2.push({ label: pa.full_name, value: pa.autorId });
                } else {
                  res.data.autors.forEach(a => {
                    if (a.id === pa.autorId) {
                      selectedOption2.push({ label: a.first_name + " " + a.last_name, value: a.id });
                    }
                  });
                }
              } else {
                res.data.autors.forEach(a => {
                  if (a.id === pa.autorId) {
                    if (a.typeAutorId === idAmbos) {
                      selectedOption1.push({ label: a.first_name + " " + a.last_name, value: a.id });
                      selectedOption2.push({ label: a.first_name + " " + a.last_name, value: a.id });
                    } else if (a.typeAutorId === idEscritor) {
                      selectedOption1.push({ label: a.first_name + " " + a.last_name, value: a.id });
                    } else {
                      selectedOption2.push({ label: a.first_name + " " + a.last_name, value: a.id });
                    }
                  }
                });
              }
            });

            if (res.data.startDatePromotion !== null && res.data.endDatePromotion !== null) {
              this.setState({ activePromotion: res.data.activePromotion, startDatePromotion: res.data.startDatePromotion.split('T')[0], endDatePromotion: res.data.endDatePromotion.split('T')[0], discount: res.data.discount });
            }

            this.setState({
              activePromotion: res.data.activePromotion,
              id: res.data.id,
              title: res.data.title,
              price: res.data.price,
              priceTapaBlanda: res.data.priceTapaBlanda,
              synopsis: res.data.synopsis,
              published: res.data.published,
              selectedOption: { label: productTypeId.name, value: productTypeId.id },
              selectedOption1: selectedOption1,
              selectedOption2: selectedOption2,
              linkCompraEBook: res.data.linkCompraEBook,
              linkCompraTapaBlanda: res.data.linkCompraTapaBlanda
            });

            if (res.data.metaDescription) {
              this.setState({ textareaMetaDescription: res.data.metaDescription });
              this.setState({ caractersInMetaDescription: res.data.metaDescription.length });
            } else {
              this.setState({ caractersInMetaDescription: 0 });
              this.setState({ textareaMetaDescription: '' });
            }

            if (res.data.metaTitle) {
              this.setState({ inputMetaTitle: res.data.metaTitle });
              this.setState({ caractersInMetaTitle: res.data.metaTitle.length });
            } else {
              this.setState({ caractersInMetaTitle: 0 });
              this.setState({ inputMetaTitle: '' });
            }

            if (res.data.metaKeywords) {
              var metaKeywords = res.data.metaKeywords;
              if (metaKeywords.length > 0) {
                if (metaKeywords.includes(',')) {
                  var arr = metaKeywords.split(',');
                  this.setState({ cantidadMetaKeywords: arr.length });
                  this.setState({ inputMetaKeywords: metaKeywords });
                } else {
                  this.setState({ cantidadMetaKeywords: 1 });
                  this.setState({ inputMetaKeywords: metaKeywords });
                }
              } else {
                this.setState({ cantidadMetaKeywords: 0 });
                this.setState({ inputMetaKeywords: '' });
              }
            } else {
              this.setState({ inputMetaKeywords: '' });
              this.setState({ cantidadMetaKeywords: 0 });
            }
          }
        }).catch(e => {
          if (e) {
            swal({
              title: `Upss,Algo salió mal inténtelo mas tarde ${e}`,
              text: "Haga click en el botón!",
              icon: "error",
            });
          }
        })
      }
    }
  }

  handleFile = (e) => {
    let file = e.target.files[0];
    this.setState({ File: file })
  }

  handleFile1 = (e) => {
    let file = e.target.files[0];
    this.setState({ FilePdf: file })
  }

  handleFile2 = (e) => {
    let file = e.target.files[0];
    this.setState({ BookPdf: file })
  }

  componentDidUpdate() {
    if (this.props.productId !== -1) {
      this.getProduct(this.props.productId);
      this.props.onRenderProducts(-1);
    }
  }

  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">Información del producto</h6>
              {this.state.loading ?
                <div>
                  <img
                    src={require("assets/img/LOADING.svg").default}
                    alt="Logo de la Editorial D`MCPherson"
                    width="80"
                  />
                </div>
                : null
              }

              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={this.addProduct}
              >
                {this.state.loading ?
                  <>Guardando Información</>
                  : this.state.id === -1 ? <>Insertar Producto</> : <>Actualizar Producto</>
                }
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">

              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Título
                    </label>
                    <input
                      type="text"
                      name="title"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.title}
                    />
                  </div>
                  {this.state.error.title !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.title}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Género
                    </label>
                    <select
                      name="selectedOption"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={this.handleSelect}
                    >
                      <option value='-1' label="Seleccione un género " selected={this.state.id !== -1 ? false : true}></option>
                      {this.state.genders.map(l => {
                        if (this.state.selectedOption !== null)
                          if (l.value === this.state.selectedOption.value)
                            return <option value={l.value} selected="true">{l.label}</option>

                        return <option value={l.value} >{l.label}</option>
                      })}
                    </select>
                  </div>
                  {this.state.error.gender !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.gender}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Precio
                    </label>
                    <input
                      type="number"
                      min="0"
                      name="price"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.price}
                    />
                  </div>
                  {this.state.error.price !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.price}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Precio Tapa Blanda
                    </label>
                    <input
                      type="number"
                      min="0"
                      name="priceTapaBlanda"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.priceTapaBlanda}
                    />
                  </div>
                  {this.state.error.priceTapaBlanda !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.priceTapaBlanda}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Imagen
                    </label>

                    {(this.imagenUrl !== "" && this.imagenUrl !== null) ?
                      <img
                        src={this.imagenUrl}
                        alt="..."
                        className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idImgLibro"
                      type="file"
                      onChange={this.handleFile}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.image !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.image}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Fragmento del libro
                    </label>

                    {(this.pdfUrl !== "" && this.pdfUrl !== null) ?
                      <img
                        src={require("assets/img/fragmento.png").default}
                        alt="Fragmento de libro de la Editorial DMCPherson"
                        className="w-10 h-10 border-2 border-blue-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idPdfLibro"
                      type="file"
                      onChange={this.handleFile1}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.pdf !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.pdf}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      PDF del libro
                    </label>

                    {(this.BookUrl !== "" && this.BookUrl !== null) ?
                      <img
                        src={require("assets/img/fragmento.png").default}
                        alt="Libro de la Editorial DMCPherson"
                        className="w-10 h-10 border-2 border-blue-50 shadow -ml-10"
                      ></img> : null
                    }

                    <input
                      id="idPdfLibro"
                      type="file"
                      onChange={this.handleFile2}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  {this.state.error.pdf !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.pdf}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Autores
                    </label>
                    <Select
                      name="selectedOption"
                      options={this.state.autors}
                      onChange={this.handleSelect1}
                      value={this.state.selectedOption1}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      isMulti
                    />
                  </div>
                  {this.state.error.autors !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.autors}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Artista Visual
                    </label>
                    <Select
                      name="selectedOption"
                      options={this.state.ilustrators}
                      onChange={this.handleSelect2}
                      value={this.state.selectedOption2}
                      className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      isMulti
                    />
                  </div>
                  {this.state.error.autors !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.autors}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Link de compra E-Book
                    </label>
                    <input
                      type="text"
                      name="linkCompraEBook"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.linkCompraEBook}
                    />
                  </div>
                  {this.state.error.linkCompraEBook !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.linkCompraEBook}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Link de compra portada de Papel
                    </label>
                    <input
                      type="text"
                      name="linkCompraTapaBlanda"
                      onChange={this.setValues}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={this.state.linkCompraTapaBlanda}
                    />
                  </div>
                  {this.state.error.linkCompraTapaBlanda !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.linkCompraTapaBlanda}
                      </span>
                    </div> : null
                  }
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Publicado
                    </label>
                    <input
                      type="checkbox"
                      name="published"
                      onChange={this.handleCheck}
                      checked={this.state.published}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Enviar notificación
                    </label>
                    <input
                      type="checkbox"
                      name="published"
                      onChange={this.handleCheck2}
                      checked={this.state.sendNotify}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Sinopsis
                  </label>
                  <textarea
                    type="text"
                    name="synopsis"
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.synopsis}
                    rows="4"
                  ></textarea>
                  {this.state.error.synopsis !== undefined ?
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                      <span className="inline-block align-middle mr-8">
                        {this.state.error.synopsis}
                      </span>
                    </div> : null
                  }
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Oferta de promoción
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Activar Oferta
                    </label>
                    <input
                      type="checkbox"
                      name="activePromotion"
                      onChange={this.handleCheckP}
                      checked={this.state.activePromotion}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                {this.state.activePromotion ? <>
                  <div className="w-full lg:w-3/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Fecha de inicio
                      </label>
                      <input
                        type="date"
                        name="startDatePromotion"
                        value={this.state.startDatePromotion}
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    {this.state.error.startDatePromotion !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.startDatePromotion}
                        </span>
                      </div> : null
                    }
                  </div>
                  <div className="w-full lg:w-3/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Fecha final
                      </label>
                      <input
                        type="date"
                        name="endDatePromotion"
                        value={this.state.endDatePromotion}
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    {this.state.error.endDatePromotion !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.endDatePromotion}
                        </span>
                      </div> : null
                    }
                  </div>
                  <div className="w-full lg:w-3/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Precio con el Descuento
                      </label>
                      <input
                        type="number"
                        name="discount"
                        min="0"
                        onChange={this.setValues}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={this.state.discount}
                      />
                    </div>
                    {this.state.error.discount !== undefined ?
                      <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-400">
                        <span className="inline-block align-middle mr-8">
                          {this.state.error.discount}
                        </span>
                      </div> : null
                    }
                  </div></> : <></>
                }
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Meta datos
              </h6>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <input
                      type="checkbox"
                      name="metaTitle"
                      onChange={this.handleCheckMetaTitle}
                      checked={this.state.metaTitle}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                    <label
                      className="inline uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      Generar título del sitio
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.caractersInMetaTitle}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      60 caracteres máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="inputMetaTitle"
                    placeholder="Especifique un título para el libro"
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.inputMetaTitle}
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mt-5"
                      htmlFor="grid-password"
                    >
                      Palabras claves separadas por coma
                  </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.cantidadMetaKeywords}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      6 palabras o frases máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <input
                    type="text"
                    name="inputMetaKeywords"
                    placeholder="palabra clave 1, palabra clave 2, palabra clave 3, ..."
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.inputMetaKeywords}
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mt-5"
                      htmlFor="grid-password"
                    >
                      Descripción
                  </label>
                  </div>
                </div>
                <div className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="uppercase font-bold mb-2 ml-1 text-3xl text-green-100">
                      {this.state.caractersInMetaDescription}
                    </label>
                    <label
                      className="uppercase text-blueGray-600 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password">
                      160 caracteres máximo (Recomendado)
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <textarea
                    type="text"
                    name="textareaMetaDescription"
                    onChange={this.setValues}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={this.state.textareaMetaDescription}
                    rows="3"
                  ></textarea>
                </div>
              </div>

            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    productId: state.productId,
    products: state.products
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRenderProducts: (productId) => dispatch({ type: actionTypes.RENDER_PRODUCT, productId: productId }),
    onRenderProductsTable: () => dispatch({ type: actionTypes.RENDER_PRODUCT_TABLE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardProduct);