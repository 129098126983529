import * as actionTypes from './actions';

const initialState = {
    settingId: 0,
    settings: false,
    userId: -1,
    users: false,
    roleId: -1,
    roles: false,
    specialtyId: -1,
    specialties: false,
    newsId: -1,
    news: false,
    contactId: -1,
    contacts: false,
    genderId: -1,
    genders: false,
    autorId: -1,
    autors: false,
    productId: -1,
    products: false,
    boletinId: -1,
    boletins: false,
    videoId: -1,
    videos: false,
    publicationId: -1,
    publications: false,
    packId: -1,
    packs: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RENDER_SETTINGS:
            return {
                ...state,
                settingId: action.settingId
            };
        case actionTypes.RENDER_SETTINGS_TABLE:
            return {
                ...state,
                settings: !state.settings
            };
        case actionTypes.RENDER_USERS:
            return {
                ...state,
                userId: action.userId
            };
        case actionTypes.RENDER_USERS_TABLE:
            return {
                ...state,
                users: !state.users
            };
        case actionTypes.RENDER_ROLES:
            return {
                ...state,
                roleId: action.roleId
            };
        case actionTypes.RENDER_ROLES_TABLE:
            return {
                ...state,
                roles: !state.roles
            };
        case actionTypes.RENDER_SPECIALTY:
            return {
                ...state,
                specialtyId: action.specialtyId
            };
        case actionTypes.RENDER_SPECIALTY_TABLE:
            return {
                ...state,
                specialties: !state.specialties
            };
        case actionTypes.RENDER_NEWS:
            return {
                ...state,
                newsId: action.newsId
            };
        case actionTypes.RENDER_NEWS_TABLE:
            return {
                ...state,
                news: !state.news
            };
        case actionTypes.RENDER_CONTACT:
            return {
                ...state,
                contactId: action.contactId
            };
        case actionTypes.RENDER_CONTACT_TABLE:
            return {
                ...state,
                contacts: !state.contacts
            };
        case actionTypes.RENDER_GENDER:
            return {
                ...state,
                genderId: action.genderId
            };
        case actionTypes.RENDER_GENDER_TABLE:
            return {
                ...state,
                genders: !state.genders
            };
        case actionTypes.RENDER_AUTOR:
            return {
                ...state,
                autorId: action.autorId
            };
        case actionTypes.RENDER_AUTOR_TABLE:
            return {
                ...state,
                autors: !state.autors
            };
        case actionTypes.RENDER_PRODUCT:
            return {
                ...state,
                productId: action.productId
            };
        case actionTypes.RENDER_PRODUCT_TABLE:
            return {
                ...state,
                products: !state.products
            };
        case actionTypes.RENDER_BOLETIN:
            return {
                ...state,
                boletinId: action.boletinId
            };
        case actionTypes.RENDER_BOLETIN_TABLE:
            return {
                ...state,
                boletins: !state.boletins
            };
        case actionTypes.RENDER_VIDEO:
            return {
                ...state,
                videoId: action.videoId
            };
        case actionTypes.RENDER_VIDEO_TABLE:
            return {
                ...state,
                videos: !state.videos
            };
        case actionTypes.RENDER_PUBLICATION:
            return {
                ...state,
                publicationId: action.publicationId
            };
        case actionTypes.RENDER_PUBLICATION_TABLE:
            return {
                ...state,
                publications: !state.publications
            };
        case actionTypes.RENDER_PACK:
            return {
                ...state,
                packId: action.packId
            };
        case actionTypes.RENDER_PACK_TABLE:
            return {
                ...state,
                packs: !state.packs
            };
        default:
            return state;
    }
};

export default reducer;