export const RENDER_SETTINGS = 'RENDER_SETTINGS';
export const RENDER_SETTINGS_TABLE = 'RENDER_SETTINGS_TABLE';
export const RENDER_USERS = 'RENDER_USERS';
export const RENDER_USERS_TABLE = 'RENDER_USERS_TABLE';
export const RENDER_ROLES = 'RENDER_ROLES';
export const RENDER_ROLES_TABLE = 'RENDER_ROLES_TABLE';
export const RENDER_SPECIALTY = 'RENDER_SPECIALTY';
export const RENDER_SPECIALTY_TABLE = 'RENDER_SPECIALTY_TABLE';
export const RENDER_NEWS = 'RENDER_NEWS';
export const RENDER_NEWS_TABLE = 'RENDER_NEWS_TABLE';
export const RENDER_CONTACT = 'RENDER_CONTACT';
export const RENDER_CONTACT_TABLE = 'RENDER_CONTACT_TABLE';
export const RENDER_GENDER = 'RENDER_GENDER';
export const RENDER_GENDER_TABLE = 'RENDER_GENDER_TABLE';
export const RENDER_AUTOR = 'RENDER_AUTOR';
export const RENDER_AUTOR_TABLE = 'RENDER_AUTOR_TABLE';
export const RENDER_PRODUCT = 'RENDER_PRODUCT';
export const RENDER_PRODUCT_TABLE = 'RENDER_PRODUCT_TABLE';
export const RENDER_BOLETIN = 'RENDER_BOLETIN';
export const RENDER_BOLETIN_TABLE = 'RENDER_BOLETIN_TABLE';
export const RENDER_RECOMENDED_BOOK = 'RENDER_RECOMENDED_BOOK';
export const RENDER_VIDEO = 'RENDER_VIDEO';
export const RENDER_VIDEO_TABLE = 'RENDER_VIDEO_TABLE';
export const RENDER_PUBLICATION = 'RENDER_PUBLICATION';
export const RENDER_PUBLICATION_TABLE = 'RENDER_PUBLICATION_TABLE';
export const RENDER_PACK = 'RENDER_PACK';
export const RENDER_PACK_TABLE = 'RENDER_PACK_TABLE';