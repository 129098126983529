import React from "react";

// components

import Contacts from "components/Cards/CardContact";
import CardTableContacts from "components/Cards/CardTableContact";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <Contacts />
        </div>
        <div className="w-full px-4">
          <CardTableContacts />
        </div>
       
      </div>
    </>
  );
}
